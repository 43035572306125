<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  class: {
    type: String,
    default: '',
  },
  active: {
    type: Boolean,
    default: false
  }
})
</script>
<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :class="props.class">
    <path d="M8 9H16M8 13H14M12.031 18.581L8 21V18H6C5.20435 18 4.44129 17.6839 3.87868 17.1213C3.31607 16.5587 3 15.7956 3 15V7C3 6.20435 3.31607 5.44129 3.87868 4.87868C4.44129 4.31607 5.20435 4 6 4H18C18.7956 4 19.5587 4.31607 20.1213 4.87868C20.6839 5.44129 21 6.20435 21 7V12M19.001 21C18.4706 21 17.9619 20.7893 17.5868 20.4142C17.2117 20.0391 17.001 19.5304 17.001 19C17.001 18.4696 17.2117 17.9609 17.5868 17.5858C17.9619 17.2107 18.4706 17 19.001 17M19.001 21C19.5314 21 20.0401 20.7893 20.4152 20.4142C20.7903 20.0391 21.001 19.5304 21.001 19C21.001 18.4696 20.7903 17.9609 20.4152 17.5858C20.0401 17.2107 19.5314 17 19.001 17M19.001 21V22.5M19.001 17V15.5M22.032 17.25L20.733 18M17.27 20L15.97 20.75M15.97 17.25L17.27 18M20.733 20L22.033 20.75" :stroke="active ? '#ED9C06': '#FEFBF2'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
