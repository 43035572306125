
import Error404 from 'views/common/404.vue'

import Login from 'views/auth/Login.vue'
import ForgotPassword from 'views/auth/ForgotPassword.vue'
import settingPassword from 'views/auth/settingPassword.vue'

import User from 'views/user/index.vue'
import Template from 'views/template/index.vue'
import Setting from 'views/setting/index.vue'
import Chat from 'views/chat/index.vue'


const routes = [
  {
    path: '/',
    component: Login,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/setting-password',
    component: settingPassword
  },

  {
    path: '/users',
    component: User,
    meta: { authorize: true }
  },
  {
    path: '/templates',
    component: Template,
    meta: { authorize: true }
  },
  {
    path: '/settings',
    component: Setting,
    meta: { authorize: true }
  },
  {
    path: '/chat',
    component: Chat,
    meta: { authorize: true }
  },

  // TODO  other pages
  {
    path: '/404',
    component: Error404,
    meta: { authorize: false }
  },
  {
    path: '/:catchAll(.*)',
    component: Error404
  },
  
]

export default routes
