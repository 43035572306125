import './bootstrap';
import { createApp } from 'vue'
import Notifications from 'notiwind'
import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import ja from './locales/ja.json'
import vi from './locales/vi.json'
import App from '../views/App.vue'
import route from './route/index.js'

import { createPinia } from 'pinia'
const pinia = createPinia()

const i18n = createI18n({
  legacy: false,
  locale: 'ja',
  fallbackLocale: 'ja',
  messages: {
    en: en,
    ja: ja,
    vi: vi
  }
})

import { Icon } from '@iconify/vue';

const app = createApp(App)
app.use(Notifications)
app.use(i18n)
app.use(route)
app.use(pinia)
app.component('Icon', Icon)
app.mount('#app')
