<template>
    <div class="h-screen flex flex-col justify-between bg-yellow-30">
      <div class="flex text-blue-910 bg-yellow-70 text-3xl font-bold px-4 h-[3rem] border-blue-800 border-b-2 items-center">
        {{ $t('app') }}
      </div>

      <div class="sm:mx-auto px-2 w-[360px]">
        <div class="bg-white p-6 sm:rounded-lg shadow-md border flex flex-col gap-4" v-if="!successFlag">
          <div class="font-bold text-lg border-b-2 border-blue-800">
            <template v-if="!firstReset">
              {{ $t('setting_pass_title') }}
            </template>
            <template v-else>
              {{ $t('setting_pass_title_first') }}
            </template>
          </div>
          <div class="text-sm text-cyan-900">
            <template v-if="!firstReset">
              <p>{{ $t('setting_pass_desc') }}</p>
            </template>
            <template v-else>
              <p>新しいパスワードを入力し、<br/> 登録を完了させて下さい。</p>
              <p class="pt-2 text-slate-950">メールアドレス<br/>&nbsp;&nbsp;{{ _email }}</p>
            </template>
          </div>
          <div>
            <label for="email" class="block text-sm">{{ $t('setting_pass_new_pass') }}</label>
            <div class="mt-1 relative">
              <input v-model="form.password"
                :type="showPass ? 'text' : 'password'"
                :class="error['flag'] ? 'border-red-500' : 'border-default'"
                class="block w-full appearance-none rounded border  px-3 py-2 placeholder-gray-400 shadow-sm focus"
                @change="validatePassword"
              />
                <button type="button" @click="showPass = !showPass" class="absolute inset-y-0 right-0 flex items-center pr-3">
                  <EyeIcon v-if="showPass" class="h-5 w-5 text-slate-750" aria-hidden="true" />
                  <EyeSlashIcon v-else class="h-5 w-5 text-slate-750" aria-hidden="true" />            
                </button>
            </div>
          </div>
          <div>
            <label for="confirmPassword" class="block text-sm">{{ $t('setting_pass_new_pass_confirm') }}</label>
            <div class="mt-1 relative">
                <input v-model="form.confirmPassword"
                :type="showPass ? 'text' : 'password'"
                :class="error['flag'] ? 'border-red-500' : 'border-default'"
                class="block w-full appearance-none rounded border  px-3 py-2 placeholder-gray-400 shadow-sm focus" 
                @change="validatePassword"
                />
                <button type="button" @click="showPass = !showPass" class="absolute inset-y-0 right-0 flex items-center pr-3">
                  <EyeIcon v-if="showPass" class="h-5 w-5 text-slate-750" aria-hidden="true" />
                  <EyeSlashIcon v-else class="h-5 w-5 text-slate-750" aria-hidden="true" />              
                </button>
                
            </div>
          </div>

          <div class="flex flex-col gap-2 text-red">
            <ul class="pl-4 text-red-700" style="list-style: square;">
              <li v-if="error['require']">{{ $t('setting_pass_error_require') }}</li>
              <li v-if="error['flag']">{{ $t('setting_pass_error_not_match') }}</li>
              <li v-if="error['condition']">{{ $t('setting_pass_error_condition') }}</li>
              <li v-if="error['min']">{{ $t('setting_pass_error_min') }}</li>
              <li v-if="error['max']">{{ $t('setting_pass_error_max') }}</li>
            </ul>
            
          </div>

          <div>
            <button 
              @click="createPassword" 
              class="flex w-full justify-center rounded py-2 px-4 font-bold text-white shadow-sm bg-blue-810 hover:opacity-80"
              :class="!form.password || !form.confirmPassword ? 'cursor-not-allowed' : ''"
              :disabled="!form.password || !form.confirmPassword"
            >
            <template v-if="firstReset">
              {{ $t('setting_pass_change_pass_first') }}
            </template>
            <template v-else>
              {{ $t('setting_pass_change_pass') }}
            </template>
              
            </button>
          </div>
        </div>
        <div class="bg-white p-6 sm:rounded-lg shadow-md border flex flex-col gap-4" v-else>
          <div class="flex flex-col text-sm text-primary gap-2">
            <div class="font-bold text-lg border-b-2 border-blue-800">
              {{ $t('setting_pass_title_success') }}
            </div>
            <div class="text-sm text-cyan-900 gap-2 flex flex-col pt-2">
              <p v-html="$t('setting_pass_desc_success')"></p>
            </div>
          </div>
        </div>

        <div class="sm:mx-auto mt-8" v-if="!firstReset">
          <div class="bg-white p-6 sm:rounded-lg shadow-md border">
            <router-link to="/"
              class="flex w-full justify-center rounded bg-blue-800 py-2 px-4 font-bold text-white shadow-sm hover:opacity-80 text-center">
              {{ $t('back_to_login') }}
            </router-link>
          </div>
        </div>

      </div>

      <div>&nbsp;</div>
    </div>
  </template>
<script setup>
import { ref, getCurrentInstance,onMounted } from 'vue'
const $root = getCurrentInstance().appContext.config.globalProperties;

import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline'

import common from 'store/common'

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

let form = ref({token:'', password:'', confirmPassword:''})
let _email = ref(null)
let successFlag = ref(false)
let showPass = ref(false)

const error = ref([])

let viewKey = ref(0)
onMounted(async () => {
  //If you are already logged in, it will redirect
  const logged = localStorage.getItem("user") ?? null;
  if (logged) {
    const user = JSON.parse(logged);
    router.push({ path: `/chat` });
  }

  form.token = route.query.token
  if(form.token){
    await checkToken()
  }
  else {
    router.push({ path: `/login` });
  }

})
const createPassword = async () =>{
    viewKey++
    await validatePassword()
    if(Object.entries(error.value).length > 0){
      return
    }

    axios.post('api/createPassword', {token:form.token, password: form.value.password, confirmPassword: form.value.confirmPassword})
    .then(response =>{
        if(response.data.code == 200) {
            successFlag.value = true
            $root.notify({
              group: 'top',
              message:  t('setting_pass_reset_success'),
              type: 'success'
            })
            firstReset.value = false
            setTimeout(() => {
                router.push({ path: `/login` });
            }, 4000)
        }
        else {
          $root.notify({
            group: 'top',
            message:  t('msg_error'),
            type: 'error'
          })
        }
        
        $root.$overlay.hide();       
    })
    .catch((err) =>{
        $root.$overlay.hide();
        $root.notify({group: 'top', message:  t('msg_error'), type: 'error'})
    });
}

let firstReset = ref(false)
const checkToken = async () => {
    $root.$overlay.show();
    
    if(!form.token) {
        router.push({ path: `/login` });
    }
    axios.post('api/checkToken', {token:form.token})
    .then(response =>{
        $root.$overlay.hide();
        if(response.data.code == 404) {
          $root.notify({
            group: 'top',
            message:  t('setting_pass_url_expire'),
            type: 'error'
          })
            router.push({ path: `/login` });
        }
        else {
          firstReset.value = response.data.first
          _email.value = response.data.email
        }

    })
}
const validatePassword = async () => {
  if(viewKey.value == 0) {return}
  //reset validate
  error.value = []

  if(form.value.password !== form.value.confirmPassword){
    error.value['flag'] = true
  }
  if(!form.value.password || !form.value.confirmPassword){
    error.value['require'] = true
  }
  if(!common.validPassword(form.value.password)) {
    error.value['condition'] = true
  }
  if(!form.value.password || form.value.password.length < 8) {
    error.value['min'] = true
  }
  if(!form.value.password || form.value.password.length > 19) {
    error.value['max'] = true
  }
}
</script>