<script setup>
import { defineProps, ref } from 'vue'
import SideBarOpen from 'views/icons/SideBarOpen.vue';
import SideBarClose from 'views/icons/SideBarClose.vue';
import MenuTemplate from 'views/icons/MenuTemplate.vue';
import MenuSetting from 'views/icons/MenuSetting.vue';
import MenuUser from 'views/icons/MenuUser.vue';

import { ChatBubbleOvalLeftEllipsisIcon, Cog6ToothIcon } from '@heroicons/vue/24/outline'

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const open = ref(false)
const menus = ref([
  { text: t('menu_chat'), path: '/chat', icon: 'ChatIcon' },
  { text: t('menu_template'), path: '/templates', icon: 'MenuTemplateIcon' },
  { text: t('menu_setting'), path: '/settings', icon: 'MenuSettingIcon' },
  { text: t('menu_user'), path: '/users', icon: 'MenuUserIcon' },

])
const getIcon = (v) => {
  switch (v) {
    case 'ChatIcon':
      return ChatBubbleOvalLeftEllipsisIcon
    case 'MenuTemplateIcon':
      return MenuTemplate
    case 'MenuSettingIcon':
      return Cog6ToothIcon
    case 'MenuUserIcon':
      return MenuUser
    default:
      return ''
  }
}
</script>
<template>
  <div class="flex left-0 top-0 bottom-0 bg-purple-100 shadow-lg z-10 relative bg-blue-910">
    <div class="flex flex-col items-center h-full text-white cursor-pointer shadow-md transition-all delay-75 duration-300"
      :class="open ? 'w-[188px]' : 'w-16'">
      <div class="flex flex-row items-center justify-center transition-all delay-75 duration-300 ease-in-out h-[60px] "
      :class="{'gap-4': open,}">
          <div class="flex-none">
            <SideBarClose @click="open = !open"
            class="w-6 h-6 fill-white hover:fill-yellow-650" :class="{'hidden': open}"/>
            <SideBarOpen @click="open = !open"
            class="w-6 h-6 fill-white hover:fill-yellow-650" :class="{'hidden': !open}"/>
          </div>
          <div class="grow cursor-default">
            <img :src="`/images/logo.png`" :class="{'hidden': !open}"/>
          </div>
      </div>
      
      <div class="flex flex-col text-center w-full items-center relative transition-all delay-75  ease-in-out duration-300">
        <div class="w-full flex flex-col">
          <template v-for="(menu, idx) in menus" :key="menu.text">
          <router-link :to="menu.path" 
            :class="{
                'text-yellow-650': menu.path == $route.path,
                'text-yellow-70': menu.path != $route.path,
                'flex-col pt-[14px] pb-[4px]': !open,
                'px-4 gap-4 py-0': open,
              }"
            class="flex items-center border-violet-250 hover:bg-slate-100/30 h-[56px]">
            <div class="py-0.5  gap-1">
              <component :is="getIcon(menu.icon)" class="h-6 w-6" :active="menu.path == $route.path"/>              
            </div>
            <div class="transition-all delay-100  ease-out duration-300"
              :class="{
                'font-semibold text-[14px]': open,
                'font-normal text-xs tracking-[-1.625px]': !open,
              }">
              {{ menu.text }}
            </div>
          </router-link>
          <div class="flex py-2" v-if="idx == 0">
            <hr class="flex border-b-[1px] w-full border-violet-250">
          </div>
        </template>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>

</style>
