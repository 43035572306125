<script setup>
import { ref, onMounted, getCurrentInstance, nextTick } from 'vue'
const $root = getCurrentInstance().appContext.config.globalProperties;

import { Listbox, ListboxLabel, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue'

import common from 'store/common'

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import Loading from 'views/common/loading.vue'

import { ChatsStore } from "store/chats";

const chatsStore = ChatsStore();

onMounted(async () => {
  await chatsStore.getRooms()
})

const selectStatus = ref(chatsStore.arrStatus[0])

const loading = ref(false)

const searchRoom = async () => {
  loading.value = true
  await chatsStore.getRooms()
  loading.value = false
}

import ChatStatusAll from 'views/icons/ChatStatusAll.vue'
import ChatStatusUnread from 'views/icons/ChatStatusUnread.vue'
import ChatStatusAction from 'views/icons/ChatStatusAction.vue'
import ChatStatusAcknowledged from 'views/icons/ChatStatusAcknowledged.vue'
import ChatStatusSpam from 'views/icons/ChatStatusSpam.vue'
const getStatusIcon = (v) => {
  switch (v) {
    case 'all':
      return ChatStatusAll
    case 'unread':
      return ChatStatusUnread
    case 'action':
      return ChatStatusAction
    case 'acknowledged':
      return ChatStatusAcknowledged
    case 'spam':
      return ChatStatusSpam
    default:
      return ''
  }
}

const changeRoom = async (idx) => {
  $root.$overlay.show()

  if (chatsStore.rooms[idx].readed == false) {
    chatsStore.rooms[idx].readed = true
  }

  chatsStore.room = chatsStore.rooms[idx]

  //get message
  await chatsStore.getMessages(chatsStore.room.id)

  //get customer info
  await chatsStore.getCustomerInfo(chatsStore.room.customer_id, chatsStore.room.id)

  //join joom
  // chatsStore.joinRoom()

  $root.$overlay.hide()

  nextTick(() => {
    //scroll to botton widget message
    document.getElementById('chat-main-messages').lastElementChild.scrollIntoView({ behavior: 'smooth' });
  })
}
const loadingMore = ref(false)
const loadMore = async () => {
  chatsStore.filterRoom.page++
  loadingMore.value = true
  await searchRoom()
  loadingMore.value = false
}

const changeStatus = async () => {
  chatsStore.filterRoom.search.status = selectStatus.value.value
  //reset page
  chatsStore.filterRoom.page = 1

  await searchRoom()
}
</script>
<template>

  <Loading :loading="loading" />

  <div class="flex justify-between p-4 gap-2 bg-white border-b border-slate-500 lg:h-[60px]">
    <div>
      <Listbox v-model="selectStatus" @update:model-value="changeStatus">
        <div class="relative min-w-[120px]">
          <ListboxButton
            class="relative w-full cursor-default rounded border h-7 border-default bg-white pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span class="block truncate">{{ selectStatus.text }}</span>
            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <Icon icon="icon-park-outline:down" height="16px" class="text-gray-600"></Icon>
            </span>
          </ListboxButton>

          <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
            leave-to-class="opacity-0">
            <ListboxOptions
              class="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              <ListboxOption v-slot="{ active, selected }" v-for="status in chatsStore.arrStatus" :key="status.value"
                :value="status" as="template" :class="`status-${status.value}`">
                <li class="relative cursor-default select-none py-2 pl-10 pr-4 my-2" :class="{
    'bg-[#989AB8] text-white': selected,
    'text-gray-900': !selected,
    'opacity-70 bg-[#989AB8] text-white': active && !selected,
  }">
                  <span :class="[selected ? 'bg-[#989AB8] text-white' : ' ', 'block truncate',]">{{ status.text
                    }}</span>
                  <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                    <Icon icon="mingcute:check-2-fill" height="16px"></Icon>
                  </span>
                  <span v-else class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                    <component :is="getStatusIcon(status.value)" :active="true" class="active" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </div>
    <div>
      <input type="search" v-model.trim="chatsStore.filterRoom.search.text"
        class="rounded h-7 border px-2 w-full border-default outline-none" @keydown.enter="searchRoom()"
        @change="searchRoom()" placeholder="検索" />
    </div>
  </div>


  <div class="divide-gray-400 divide-y-2 drop-shadow wap-rooms">
    <template v-for="(room, idx) in chatsStore.rooms">
      <div class="w-full flex gap-2 p-1.5 room bg-white cursor-pointer hover:bg-slate-100" @click="changeRoom(idx)"
        :class="room.id == chatsStore.room.id ? 'active' : ''">
        <div class="w-9 flex-none">
          <div>
            <img :src="room.avatar ? `${room.avatar}` : 'images/avata-default.png'" class="avatar circle" />
          </div>
          <div class="flex justify-end pt-1">
            <img :src="`images/logo_${room.type}.png`" class="h-6" />
          </div>
        </div>

        <div class="grow">
          <div class="flex flex-row justify-between gap-2">
            <div class="text-sm">
              <div class="line-clamp-1 whitespace-pre-line customer-name"
                v-html="common.boldMatchSearch(room.name_fa ? room.name_fa : room.name, chatsStore.filterRoom.search.text)">
              </div>
            </div>
            <div class="w-16" :class="`status-${room.status}`">
              {{ chatsStore.arrStatus.find(status => status.value == room.status)?.text }}
            </div>
          </div>
          <div class="flex justify-end date-time" :title="room.updated_at">
            {{ room.updated_at }}
          </div>
          <div class="flex flex-row justify-between gap-2">
            <div class="text-slate-500 text-xs">
              <div class="line-clamp-1 whitespace-pre-line" v-html="room.lastest_msg" style="overflow-wrap: anywhere;">
              </div>
            </div>
            <div class="w-4 h-4">
              <ChatStatusUnread v-if="room.readed == false" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="w-full flex gap-2 bg-white cursor-pointer hover:bg-slate-100 items-center justify-center">
      <template v-if="chatsStore.paginationRoom.currentPage < chatsStore.paginationRoom.lastPage">
        <button
          class="flex w-full justify-center bg-blue-810 py-2 px-4 text-white shadow-sm hover:opacity-80 tracking-widest"
          @click="loadMore()">
          <span>もっと読み込む</span>
          <Icon v-if="loadingMore" icon="eos-icons:three-dots-loading" height="24px"
            class="hover:scale-150 transition-all delay-75 duration-300 ease-out cursor-pointer hover:text-rose-500">
          </Icon>
        </button>
      </template>
    </div>
  </div>
</template>

<style scoped>
.wap-rooms {
  max-height: calc(100vh - 62px);
  overflow-y: auto;
}

.wap-rooms div[class*="status-"] {
  padding: 0 2px;
  border-radius: 3px;
  font-size: 13px;
  text-align: center;
  min-width: 64px;
  border: 1px solid #CCCCCC;
}

.wap-rooms .status-action {
  border: 1px solid #F39800 !important;
  color: #F39800 !important;
  background-color: #FEFBF2 !important;
  font-weight: 500;
}

.wap-rooms .status-acknowledged {
  color: #808080;
  border: 2px solid #CCCCCC
}

.room.active {
  border: 4px solid #06C755 !important;
}
</style>