<script setup>
import { ref, defineExpose, getCurrentInstance } from 'vue'
const $root = getCurrentInstance()?.appContext.config.globalProperties

import { TransitionRoot, TransitionChild, Dialog, DialogPanel, Tab } from '@headlessui/vue'

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const isOpenDialog = ref(false)

import { ChatsStore } from "store/chats";
const chatsStore = ChatsStore();

const error = ref({})
let pTop = ref(200)
const show = ($memo={}, position) => {
  isOpenDialog.value = true

  if($memo.id > 0){
    chatsStore.memo = Object.assign({}, $memo)
    pTop.value = position.top
  }
  else {
    const newMemo = {customer_id: chatsStore.room.customer_id, text:''}
    chatsStore.memo = Object.assign({}, newMemo)

    const customerAvatarH = document.getElementById(`customer-avatar`).offsetHeight
    pTop.value = (customerAvatarH - 180)+'px'

  }  
  error.value = {}
}
defineExpose({ show })

const saveMemo = () => {
  validateMemo()
  if(Object.entries(error.value).length > 0){
    return;
  }

  $root.$overlay.show()
  chatsStore.saveCustomerMemo().then(response => {
    if(response.code == 200){
      $root.notify({group: 'top', message: t('msg_success'), type: 'success'})
    }
    else{
      $root.notify({ group: 'top', message: t('msg_save_error'), type: 'error' })
    }
    isOpenDialog.value = false
    $root.$overlay.hide()
  })
  .catch((err) =>{
      $root.$overlay.hide();
      isOpenDialog.value = false
      $root.notify({ group: 'top', message: t('msg_save_error'), type: 'error' })
      console.log(err);
  });
}

const validateMemo = () =>{
  error.value = {}
  if(!chatsStore.memo.text) {
    error.value.text = true
  }
}

</script>
<template>
  <TransitionRoot appear :show="isOpenDialog" as="template">
    <Dialog as="div" class="relative z-10">
      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              :style="{ top: pTop }"
              class="w-full max-w-md transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all absolute right-[40px] rounded border border-blue-910">
              <div class="flex flex-col p-4 gap-4">
                <div class="bg-[#F3F5F7] rounded px-4 py-2 text-blue-910 h-9 flex justify-between">
                  <div class="font-bold">メモ</div>
                  <div>{{ chatsStore.memo.text.length }}/200</div>
                </div>

                <div>
                  <textarea v-model.trim="chatsStore.memo.text"
                  maxlength="200"
                  :class="error.text ? 'border-red-500' : 'border-default'"
                  class="p-2 w-full h-[120px] border  rounded-lg resize-none placeholder:text-gray-400 placeholder:text-sm !outline-none" 
                  inputmode="text" 
                  placeholder="メッセージを入力してください。"></textarea>
                </div>

              <div class=" flex mx-auto justify-center gap-6">
                <button type="button"
                  class="btn-cancel shadow"
                  @click="isOpenDialog = false">キャンセル</button>

                <button type="button" 
                  class="btn-save shadow"
                  @click="saveMemo">保存</button>                
              </div>
              </div>
              
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>

</style>