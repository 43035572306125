import axios from "axios";
import { defineStore } from "pinia";
import common from 'store/common'

export const ChatsStore = defineStore('chats-store', {
  state: () => ({
    filterRoom: { limit: 15, page: 1, search: { text: '', tag: [], status: 'all' } },
    rooms: [],
    room: {},
    paginationRoom: { total: 0, currentPage: 1, perPage: 1, lastPage: 1 },

    filterMessages: { limit: 20, page: 1, search: '' },
    messages: [],
    message: { message: '', attachs: [] },
    intMessage: { message: '', attachs: [] },
    error: {},

    tags: [],
    customerTags: [],

    memos: [],
    memo: {},

    info: {},

    arrStatus: [
      { value: 'all', text: 'すべて' },
      { value: 'unread', text: '未読' },
      { value: 'action', text: '要対応' },
      { value: 'acknowledged', text: '対応済み' },
      { value: 'spam', text: 'スパム' },
    ],
    keyView: 0,
  }),
  getters: {
  },

  actions: {
    connectSocket() {
      if (!window.soketiEnable) {
        return;
      }
      const channel2 = window.Echo.private('TS.CHAT');
      channel2.listen('NewMessageEvent', (e) => {
        // console.log(e, 'connectSocket')
        //add new or update room
        if (e.data.room) {
          e.data.room.updated_at = common.formartFullDate(e.data.room.updated_at)
          //update rooms, will move room update to top list          
          if (this.rooms[0].id === e.data.room.id) {
            this.rooms[0] = e.data.room
          }
          else {
            const findIdxRoom = this.rooms.findIndex(room => room.id === e.data.room.id)
            if (findIdxRoom) {
              this.rooms = this.rooms.filter(room => room.id !== e.data.room.id)
            }
            this.rooms.unshift(e.data.room);
          }

          //update room
          if (this.room.id === e.data.room.id) {
            this.room = e.data.room
          }
        }
        //add new message
        if (e.data.message && e.data.message.room_id == this.room.id) {
          const findIdxMsg = this.messages.findIndex(msg => msg.id === e.data.message.id)
          if (findIdxMsg < 0) {
            this.messages.push(e.data.message);
          }
        }

        // update realtime status
        if (e.data.status) {
          const findIdx = this.rooms.findIndex(room => room.id === e.data.status.id)
          if (findIdx >= 0) {
            this.rooms[findIdx].status = e.data.status.status
          }
        }

        //update realtime Memo
        if (e.data.memos && e.data.memos[0].customer_id == this.room.customer_id) {
          this.memos = e.data.memos
        }

        //update realtime Info customer
        if (e.data.info) {
          this.rooms.map(room => {
            if (room.customer_id == e.data.info.id) {
              room.name_fa = e.data.info.name_fa
            }
            return room
          })
          if (e.data.info.id == this.info.id) {
            this.info = e.data.info
          }
        }

        // this.keyView++
        //scroll to botton widget message
        setTimeout(() => {
          document.getElementById('chat-main-messages').lastElementChild.scrollIntoView({ behavior: 'smooth' });
        }, 500)

      });

    },

    disconnectSocket() {
      if (!window.soketiEnable) {
        return;
      }
      const channel2 = window.Echo.private('TS.CHAT');
      channel2.stopListening('NewMessageEvent');
    },

    async getRooms() {
      const response = await axios.post(`/api/get-roooms`, this.filterRoom)
      const arrRoom = response.data.data.map(room => {
        room.updated_at = common.formartFullDate(room.updated_at)
        return room
      })
      if (this.filterRoom.page == 1) {
        this.rooms = arrRoom
      }
      else {
        this.rooms.push(...arrRoom)
      }

      if (this.rooms.length > 0 && this.filterRoom.page == 1) {
        //assign
        this.room = this.rooms[0]

        if (this.room.readed == false) {
          this.room.readed = true
        }
      }
      else {
        this.room = {}
      }

      this.paginationRoom.total = response.data.total
      this.paginationRoom.currentPage = response.data.current_page
      this.paginationRoom.perPage = response.data.per_page
      this.paginationRoom.lastPage = response.data.last_page

      this.keyView++

    },

    async getMessages($room_id) {
      this.filterMessages.room_id = $room_id
      const response = await axios.post(`/api/messages`, this.filterMessages)
      this.messages = response.data
    },
    async saveMessage() {
      this.message.room_id = this.room.id
      this.message.receiver_id = this.room.customer_id

      const response = await axios.post('/api/message/save', this.message)
      return response.data
    },

    async getCustomerInfo($customer_id, $room_id) {
      const response = await axios.post(`/api/customer`, { customer_id: $customer_id, room_id: $room_id })
      if (response.data.tags) {
        this.tags = response.data.tags
      }
      if (response.data.customerTags) {
        this.customerTags = response.data.customerTags
      }

      if (response.data.memos) {
        this.memos = response.data.memos
      }

      if (response.data.info) {
        this.info = response.data.info
      }
    },
    async saveCustomerTag(tags) {
      const response = await axios.post(`/api/customer-tags/save`, { customer_id: this.room.customer_id, tags: tags, room_id: this.room.id });
      return response.data ?? []
    },

    async saveCustomerMemo() {
      const response = await axios.post(`/api/customer-memo/save`, this.memo);

      if (response.data.memos) {
        this.memos = response.data.memos
      }
      return response.data
    },

    async updateMemoSort() {
      const response = await axios.post('/api/customer-memo/update-sort', { memos: this.memos, customer_id: this.room.customer_id })
      if (response.data.memos) {
        this.memos = response.data.memos
      }
      return response.data
    },

    async deleteCustomerMemo() {
      const response = await axios.post('/api/customer-memo/delete', { id: this.memo.id, customer_id: this.memo.customer_id })
      if (response.data.memos) {
        this.memos = response.data.memos
      }
      return response.data
    },

    async saveCustomerInfo() {
      const response = await axios.post(`/api/customer-info/save`, this.info);

      if (response.data.info) {
        this.info = response.data.info
      }
      return response.data
    },

  }
});