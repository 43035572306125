<script setup>
import { ref, getCurrentInstance } from 'vue'
const $root = getCurrentInstance().appContext.config.globalProperties;

import { useRouter } from "vue-router";
const router = useRouter();

import common from 'store/common'

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import { AuthStore } from "store/auth";
const authStore = AuthStore();

const email = ref('')
let countClick = ref(0)
let successFlag = ref(false)

const send = async () => {
  countClick.value++
  // valid email is email correct format
  const emailRegex = common.isValidEmail(email.value)
  if (!emailRegex) {
    $root.notify({
      group: 'top',
      message: t('msg_email_false'),
      type: 'error'
    })
    return;
  }

  $root.$overlay.show();
  const response = await authStore.forgotPassword(email.value);
  $root.$overlay.hide();

  if (response.code == 404) {
    $root.notify({
      group: 'top',
      message: t('notice_forgot_email_not_exist'),
      type: 'error'
    })
    return;
  }
  else {
    $root.notify({
      group: 'top',
      message: t('notice_forgot_url_reset'),
      type: 'success'
    })
    // axios.get("/api/notification/forgot-password")
  }
  successFlag.value = true

}
</script>

<template>
  <div class="h-screen flex flex-col justify-between bg-yellow-30">
    <div class="flex text-blue-910 bg-yellow-70 text-3xl font-bold px-4 h-[3rem] border-blue-800 border-b-2 items-center">
      {{ $t('app') }}
    </div>

    <div class="sm:mx-auto w-[360px] px-2">
      <div class="bg-white p-6 sm:rounded-lg shadow-md border flex flex-col gap-4" v-if="!successFlag">
        <div class="font-bold text-lg border-b-2 border-blue-800">{{ $t('forgot_password_title') }}</div>
        <div class="text-sm text-cyan-900 flex flex-col gap-2">
          <p v-html="$t('forgot_password_text')"></p>
        </div>
        <div>
          <label for="email" class="block text-sm">{{ $t('login_email') }}</label>
          <div class="mt-1">
            <input v-model="email" :class="email == '' && countClick > 0 ? 'border-red-500' : 'border-default'"
              @keydown.enter="send()" id="email"
              class="block w-full appearance-none rounded border  px-3 py-2 placeholder-gray-400 shadow-sm focus"
              placeholder="sample＠sample.jp" />
          </div>
        </div>

        <div>
          <button @click="send"
          :disable="!email"
          :class="!email ? 'cursor-not-allowed' : ''"
            class="flex w-full justify-center rounded bg-blue-810 py-2 px-4 font-bold text-white shadow-sm hover:opacity-80">
            {{ $t('send') }}
          </button>
        </div>
      </div>
      <div class="bg-white p-6 sm:rounded-lg shadow-md border flex flex-col gap-4" v-else>
        <div class="font-bold text-lg border-b-2 border-blue-800">{{ $t('forgot_msg_success_title') }}</div>
        <div class="text-sm text-cyan-900 flex flex-col gap-2">          
          <p v-html="$t('forgot_msg_success')"></p>
        </div>
      </div>


      <div class="sm:mx-auto mt-8">
        <div class="bg-white p-6 sm:rounded-lg shadow-md border">
          <router-link to="/"
            class="flex w-full justify-center rounded bg-blue-800 py-2 px-4 font-bold text-white shadow-sm hover:opacity-80 text-center">
            {{ $t('back_to_login') }}
          </router-link>
        </div>
      </div>

    </div>

    <div>&nbsp;</div>


  </div>
</template>