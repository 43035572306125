<script setup>
import { ref, watch, getCurrentInstance, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { notify, Notification, NotificationGroup } from 'notiwind'
import { XMarkIcon, CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/24/outline'
import OverlayVue from 'views/common/overlay.vue'
import SidePanelBase from 'views/common/SidePanelBase.vue'
const $root = getCurrentInstance()?.appContext.config.globalProperties
const $route = useRoute()

const overlay = ref(null)

watch(() => $route, () => {
  overlay.hide()
})
const user = ref({})
onMounted(async () => {
  if ($root) {
    $root.notify = notify
    $root.$overlay = overlay.value
  }
  user.value = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {};
})
</script>
<template>
  <div class="text-base" v-if="user.id == undefined">
    <router-view></router-view>
  </div>
  <div class="flex w-full h-screen-sticky overflow-hidden transition-all delay-75 duration-300 text-base" v-else>
    <div class="h-full flex">
      <SidePanelBase></SidePanelBase>
    </div>
    <div class="flex flex-col w-full overflow-auto bg-yellow-30">      
      <router-view></router-view>
    </div>

  </div>
  <NotificationGroup group="top">
    <div class="pointer-events-none fixed inset-0 z-50 flex items-start justify-end p-6 px-4 py-6">
      <div class="w-full max-w-sm">
        <Notification v-slot="{ notifications, close }" enter="ease-out duration-300 transition"
          enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
          enter-to="translate-y-0 opacity-100 sm:translate-x-0" leave="transition ease-in duration-500"
          leave-from="opacity-100" leave-to="opacity-0" move="transition duration-500" move-delay="delay-300">
          <div v-for="(notification, idx) in notifications" :key="notification.id"
            class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-xl border-2 bg-white shadow-2xl" :class="{
    'mt-8': idx == 0,
    'mt-2': idx > 0,
    'border-green-500': notification.type == 'success',
    'border-orange-500': notification.type == 'warning',
    'border-red-500': notification.type == 'error'
  }">
            <div class="p-4">
              <div class="flex items-center">
                <div class="shrink-0">
                  <CheckCircleIcon v-if="notification.type == 'success'" class="h-10 w-10 text-green-500"
                    aria-hidden="true" />
                  <ExclamationCircleIcon v-else class="h-10 w-10" :class="{
    'text-red-500': notification.type == 'error',
    'text-orange-500': notification.type == 'warning'
  }" aria-hidden="true" />
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p v-if="notification.title" class="text-md font-semibold text-slate-950">
                    {{ notification.title }}
                  </p>
                  <p class="text-sm font-normal text-slate-950" v-html="notification.message ">
                  </p>
                </div>
                <div class="ml-4 flex shrink-0">
                  <button
                    class="text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:ring-indigo-500 inline-flex rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-offset-2"
                    @click.prevent="close(notification.id)">
                    <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Notification>
      </div>
    </div>
  </NotificationGroup>
  <OverlayVue ref="overlay" />
</template>
