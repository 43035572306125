<script setup>
import { ref, onMounted, getCurrentInstance, defineProps } from 'vue'
const props = defineProps({
  room_id: {
    type: Number,
    required: true,
  }
})
import common from 'store/common'
import { VueDraggableNext } from 'vue-draggable-next'

import { ChatsStore } from "store/chats";
const chatsStore = ChatsStore();

import PopupTag from './PopupTag.vue';
const refPopupTag = ref(null)
import PopupMemo from './PopupMemo.vue';
const refPopupMemo = ref(null)
import PopupDeleteMemo from './PopupDeleteMemo.vue';
const refPopupDeleteMemo = ref(null)
import PopupUpdateInfo from './PopupUpdateInfo.vue';
const refPopupUpdateInfo = ref(null)


onMounted(async () => {
  //get custommer info
  if (chatsStore.room.customer_id > 0) {
    await chatsStore.getCustomerInfo(chatsStore.room.customer_id, chatsStore.room.id)
  }
})

const editMemo = (memo) => {
  const clickedElement = document.getElementById(`memo${memo.id}`)
  const { left, top } = clickedElement.getBoundingClientRect();
  refPopupMemo.value.show(memo, { left: left, top: top - 260 + 'px' })
}

const deleteMemo = (memo) => {
  const clickedElement = document.getElementById(`memo${memo.id}`)
  const { left, top } = clickedElement.getBoundingClientRect();
  refPopupDeleteMemo.value.show(memo, { left: left, top: top - 180 + 'px' })
}
const getTagName = (tag_id) => {
  return chatsStore.tags.find(tag => tag.id === tag_id)?.text
}
const getTagBackground = (tag_id) => {
  const _bgColor = chatsStore.tags.find(tag => tag.id === tag_id)
  let _style = `background: ${_bgColor.color};`
  _style += ` color: ${common.autoContrastColor(_bgColor.color)};`

  return _style;
}

const updateMemoSort = async () => {
  await chatsStore.updateMemoSort()
}
</script>
<template>
  <div class="flex flex-col gap-2">
    <div class="flex flex-col justify-center items-center h-[208px] gap-2 bg-yellow-70 border-b border-[#686F84]"
      id="customer-avatar">
      <div>
        <img :src="chatsStore.room.avatar ? `${chatsStore.room.avatar}` : 'images/avata-default.png'"
          class="avatar circle" />
      </div>
      <div class="gap-2 flex flex-row items-center px-4" :id="`info_${chatsStore.room.customer_id}`">
        <div class="h-8 w-8 min-w-8"><img :src="`images/logo_${chatsStore.room.type}.png`" /></div>
        <div class="flex flex-col items-center">
          <div class="line-clamp-1 text-sm text-gray-600" v-if="chatsStore.info.name_fa">
            {{ common.convertToEmoji(chatsStore.info.name_fa) }}
          </div>
          <div class="line-clamp-1 text-sm text-gray-600" v-if="chatsStore.room.type == 'email'">{{
          chatsStore.info.email }}</div>
          <div class="line-clamp-1">({{ chatsStore.info.name }})</div>
        </div>
        <div class="min-w-5">
          <button @click="refPopupUpdateInfo.show()"
            class="flex bg-green-700 items-center justify-center rounded-full h-5 w-5 hover:opacity-70 hover:shadow-sm hover:scale-150 transition-all delay-75 duration-300 ease-out">
            <Icon icon="ic:round-edit" class="text-white"></Icon>
          </button>
        </div>
      </div>

      <div class="flex flex-row gap-1 items-center">
        <template v-for="ctag in chatsStore.customerTags">
          <button :style="getTagBackground(ctag)"
            class="tag rounded flex flex-row items-center gap-1 font-normal text-xs cursor-default border p-1 h-[22px]">
            <span class="px-2">{{ getTagName(ctag) }}</span>
          </button>
        </template>
        <button @click="refPopupTag.show()" v-if="chatsStore.customerTags.length == 0"
          class="flex flex-row items-center gap-1 p-1 hover:opacity-80 hover:bg-white hover:rounded  text-rose-500 text-xs font-bold h-[22px]">
          <Icon icon="ci:add-plus" class="text-xl"></Icon>
          <span>タグ追加</span>
          <Icon icon="mdi:tag-outline" class="text-base font-bold"></Icon>
        </button>
        <button @click="refPopupTag.show()" v-else
          class="flex flex-row items-center gap-1 p-1 hover:opacity-80 hover:bg-white rounded border border-rose-500 text-rose-500 text-xs font-bold h-[22px]">
          <Icon icon="carbon:tag-edit" height="18px"></Icon>
          <span>タグ編集</span>
        </button>
      </div>
      <div class="flex flex-row px-4" v-if="chatsStore.customerTags.length > 0">
        <div class="bg-[#E63946] text-xs font-bold text-white px-2 mb-2 h-[18px] leading-[18px]">
          ※スパムタグがある為、通知はなし、メッセージ一覧に表示されません</div>
      </div>
    </div>
    <div class="px-4">
      <div class="flex justify-between items-center font-bold">
        <div>メモ</div>
        <div class="">
          <button @click="refPopupMemo.show()"
            class="flex flex-row items-center gap-1 p-2 text-rose-500 hover:text-rose-400 text-xs font-bold">
            <Icon icon="ci:add-plus" height="16px"></Icon>
            <span>メモ追加 </span>
            <Icon icon="mage:edit" height="16px"></Icon>
          </button>
        </div>
      </div>
    </div>

    <div class="px-2 wap-memos py-2">

      <VueDraggableNext class="flex flex-col" v-model="chatsStore.memos" item-key="sort" @start="isDragging = true"
        @end="isDragging = false" @change="updateMemoSort">
        <transition-group>

          <div
            class="flex flex-col bg-white px-4 py-2 drop-shadow-lg gap-4 cursor-move mb-2 hover:bg-slate-100 active:bg-slate-200"
            v-for="(memo, idx) in chatsStore.memos" :key="idx">
            <div class="text-sm whitespace-pre-line " v-html="memo.text"></div>
            <div class="flex justify-between" :id="`memo${memo.id}`">
              <div class="date-time">{{ common.formartFullDate(memo.updated_time) }}</div>
              <div class="flex gap-2">
                <button @click="editMemo(memo)"
                  class="flex bg-green-700 items-center justify-center rounded-full h-5 w-5 hover:opacity-70 hover:shadow-sm hover:scale-150 transition-all delay-75 duration-300 ease-out">
                  <Icon icon="ic:round-edit" class="text-white"></Icon>
                </button>
                <button @click="deleteMemo(memo)"
                  class="flex bg-[#EC7E80] items-center justify-center rounded-full h-5 w-5 hover:opacity-70 hover:shadow-sm hover:scale-150 transition-all delay-75 duration-300 ease-out">
                  <Icon icon="ic:baseline-delete" class="text-white"></Icon>
                </button>
              </div>
            </div>
          </div>

        </transition-group>
      </VueDraggableNext>

    </div>
  </div>

  <PopupTag ref="refPopupTag" />
  <PopupMemo ref="refPopupMemo" />
  <PopupDeleteMemo ref="refPopupDeleteMemo" />
  <PopupUpdateInfo ref="refPopupUpdateInfo" />
</template>

<style scoped>
.wap-memos {
  max-height: calc(100vh - 208px - 60px);
  overflow-y: auto;
}
</style>