<script setup>
import {ref, defineProps } from 'vue'
const props = defineProps({
  attachs: {
    type: Array,
    required: true,
  }
})

import PopupImage from 'views/common/PopupImage.vue'
const refImage = ref(null)

const viewCarousel = (attach) => {
  if(attach.img) {
    refImage.value.show(attach.file)
  }
}

const downloadFile = (attach) => {
  if(!attach.img){
    let a= document.createElement('a')
    a.target= '_blank'
    a.download= attach.name
    a.href= attach.file
    a.click()
  }
}
const showImg = (attach) => {
  if(attach.img){
    return `background-image: url('${attach.file}')`
  }
}
</script>
<!-- using show attach in message -->
<template>
  <template v-for="(attach, idxf) in props.attachs">
    
    <template v-if="attach.ext == 'm4a'">
      <div class="flex flex-row">
        <audio controls="controls">
          <source :src="attach.file"/>
        </audio>
      </div>      
    </template>

    <template v-else-if="attach.type == 'video'">
      <div class="flex flex-row">
        <video controls="controls">
          <source :src="attach.file"/>
        </video>
      </div>      
    </template>

    <template v-else>
      <div class="h-24 w-24 rounded-md text-white" :class="{'bg-gray-700': !attach.img}">
        <div class="flex flex-col justify-between img-preview rounded-md"
        :class="{'cursor-zoom-in': attach.img, 'border': !attach.img}"
        :title="attach.name"
        @click="viewCarousel(attach)"
          :style="showImg(attach)">
          <div class="flex justify-end">
            <div>&nbsp;</div>
          </div>
          <div class="flex justify-center font-logo uppercase">
            <span v-if="!attach.img" class=" bg-black px-2">{{ attach.ext }}</span>
          </div>
          <div class="px-1">
            <div class="text-xs line-clamp-2 text-slate-100 break-words hover:underline cursor-pointer" v-if="!attach.img" 
            @click="downloadFile(attach)">            
                {{ attach.name }}
            </div>
          </div>
        </div>
      </div>
    </template>
    
  </template>
  <PopupImage ref="refImage"/>
</template>
<style scoped>
</style>