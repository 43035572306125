import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes
})
import { AuthStore } from 'store/auth'

router.beforeEach(async (to, from, next) => {
  const authorize = to.meta.authorize || null;
  const userStore = AuthStore()
  
  if (authorize == true) {    
    if (userStore.user.id == undefined) {
      next({
        path: '/login',
      })
      return
    }
    else next();
  }
  else next();

  window.axios.defaults.headers.common['Authorization'] = '';
  if (userStore.user && userStore.user.token) {    
    window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + userStore.user.token;
  }

})

export default router
