<script setup>
import { ref, defineExpose, getCurrentInstance } from 'vue'
const $root = getCurrentInstance()?.appContext.config.globalProperties

import { TransitionRoot, TransitionChild, Dialog, DialogPanel, Tab } from '@headlessui/vue'

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const isOpenDialog = ref(false)
let tmpCustomerTags = ref([])

import { ChatsStore } from "store/chats";
const chatsStore = ChatsStore();

const show = ($value=true) => {
  isOpenDialog.value = $value

  tmpCustomerTags.value = Object.assign([], JSON.parse(JSON.stringify(chatsStore.customerTags)));

  //reset show tags
  chatsStore.tags.map(tag => {
    tag.show  = tmpCustomerTags.value.includes(tag.id) ? false : true
  })
}
defineExpose({ show })


const selectTag = (tag_id) => {
  tmpCustomerTags.value.push(tag_id)
  chatsStore.tags.map(tag => {
      if (tag.id === tag_id) tag.show = false
  })
}
const removeTag = (tag_id) => {
  tmpCustomerTags.value = tmpCustomerTags.value.filter(ctag => ctag !== tag_id)
  chatsStore.tags.map(tag => {
      if (tag.id === tag_id) tag.show = show
  })
}

const saveTag = () => {
  $root.$overlay.show()
  chatsStore.saveCustomerTag(tmpCustomerTags.value).then(response => {
    if(response.customerTags){
      chatsStore.customerTags = response.customerTags
      $root.notify({group: 'top', message: t('msg_success'), type: 'success'})
    }
    else{
      $root.notify({ group: 'top', message: t('msg_save_error'), type: 'error' })
    }
    isOpenDialog.value = false
    $root.$overlay.hide()
  })
  .catch((err) =>{
      $root.$overlay.hide();
      isOpenDialog.value = false
      $root.notify({ group: 'top', message: t('msg_save_error'), type: 'error' })
      console.log(err);
  });
}
const cancelUpdateTag = () => {
  isOpenDialog.value = false
  tmpCustomerTags.value = []
}

const getTagName = (tag_id) => {
  return chatsStore.tags.find(tag => tag.id === tag_id)?.text
}
</script>
<template>
  <TransitionRoot appear :show="isOpenDialog" as="template">
    <Dialog as="div" class="relative z-10">
      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all absolute right-[40px] top-[200px] rounded border border-blue-910">
              <div class="flex flex-col p-4 gap-4">
                <div class="bg-[#F3F5F7] rounded px-4 py-2 font-bold text-blue-910 h-9">
                  タグ設定
                </div>

                <div class="text-[#9E9E9E] border border-default rounded px-4 py-2 h-24">
                  <div v-if="tmpCustomerTags.length == 0">
                    下から、設定するタグを追加してください。<br/>・クリックで追加できます。
                  </div>
                  <div class="flex gap-2" v-else>
                    <template v-for="ctag in tmpCustomerTags">
                      <button class="tag rounded flex flex-row items-center gap-1 font-normal text-xs cursor-default">
                        <span class="border-r px-2">{{ getTagName(ctag) }}</span>
                        <Icon icon="ep:close-bold" class="hover:scale-150 transition-all delay-75 duration-300 ease-out cursor-pointer hover:text-rose-500" @click="removeTag(ctag)"></Icon>
                      </button>
                    </template>
                  </div>
                  
                </div>

                <div class="text-[#9E9E9E] border border-default rounded px-4 py-2 h-24">
                  <div class="flex gap-2">
                    <template v-for="tag in chatsStore.tags">
                      <button 
                      v-if="tag.show"
                      @click="selectTag(tag.id)"
                      class="tag rounded flex flex-row items-center gap-1 font-normal text-xs">
                        <Icon icon="mdi:tag-outline"></Icon>
                        <span>{{ tag.text }}</span>
                      </button>
                    </template>
                  </div>
                </div>

              <div class="mt-8 flex mx-auto justify-center gap-6">
                <button type="button"
                  class="btn-cancel shadow"
                  @click="cancelUpdateTag">キャンセル</button>

                <button type="button" class="btn-save shadow"
                  @click="saveTag">保存</button>                
              </div>
              </div>
              
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
.tag{
  background-color: #9747FF;
  color: #FFF;
  padding: 2px 8px;

}
</style>