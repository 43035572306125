<script setup>
import { ref } from 'vue'

const loading = ref(false)

const show = () => (loading.value = true)
const hide = () => setTimeout(() => (loading.value = false), 500)

defineExpose({ show, hide })
</script>
<template>
  <div
    v-show="loading"
    class="bg-black/25 fixed inset-0 z-50 transition-all delay-75 duration-300 ease-in-out"
  >
    <div class="fixed inset-0 bg-slate-950/50"></div>

    <div class="flex min-h-full items-center justify-center p-4 text-center">
      <svg
        class="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  </div>
</template>
