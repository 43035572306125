<script setup>
import { ref, getCurrentInstance } from 'vue'

import { useRouter } from "vue-router";
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline'

import common from 'store/common'

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const router = useRouter();
const $root = getCurrentInstance().appContext.config.globalProperties;

import { AuthStore } from "store/auth";
const authStore = AuthStore();
const error = ref({ type: '', title: '', message: '' });
const auth = ref({ email: '', password: '' });
let showPass = ref(false);
// auto login/logout
const logged = localStorage.getItem("user") ?? null;
if (logged) {
  const user = JSON.parse(logged);
  authStore.setUser(user);

  router.push({ path: `/chat` });
} else {
  error.value = {
    type: "success",
    title: "",
    message: "",
  };
}

const login = async () => {

  // validate email and password
  if (!auth.value.email || !auth.value.password) {
    error.value = {
      type: "error",
      title: "",
      message: t('msg_require_login'),
    };

    return;
  }

  // valid email is email correct format
  const emailRegex = common.isValidEmail(auth.value.email)
  if (!emailRegex) {
    error.value = {
      type: "error",
      title: "",
      message: t('msg_email_false'),
    };
    return;
  }

  $root.$overlay.show();
  const response = await authStore.login(auth.value.email, auth.value.password);
  $root.$overlay.hide();
  if (response.success === false) {
    error.value = {
      type: "error",
      title: "エラー",
      message: t('msg_login_false'),
    };
    return;
  }
  else {
    error.value = {
      type: "success",
      title: "",
      message: t('msg_login_success'),
    };
  }
  location.reload()
}

</script>
<template>
  <div class="h-screen omt__table bg-yellow-350 justify-between flex flex-col">
    <div class="flex text-blue-910 bg-yellow-70 text-3xl font-bold px-4 h-[3rem] border-blue-800 border-b-2 items-center">
      {{ $t('app') }}
    </div>

    <div class="mx-auto w-[600px] px-2">
      <div class="flex flex-row justify-between border-blue-800 border-b-4 relative h-[62px]">
        <div class="text-blue-910 text-6xl font-bold">LOGIN</div>
        <div class=""><img :src="`images/motor-login.png`" /></div>
      </div>

      <div v-if="error.message" class="mt-1">
        <div class="border-t-[3px] bg-white py-2 px-1 w-fit m-auto shadow-md " :class="{
        'border-rose-600': error.type === 'error',
        'border-green': error.type === 'success',
      }">
          <div class="flex">
            <div class="ml-3">
              <p class="text-sm text-gray-410">
                <span v-if="error.title" class="text-rose-600 font-bold">{{ error.title }}</span>
                <span>{{ error.message }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="w-[424px] min-h-[220px] mx-auto gap-4 flex flex-col mt-8">
        <div class="flex flex-row items-center gap-4">
          <div class="w-4/12 font-bold text-right text-base">ＬＯＧＩＮＩＤ</div>
          <div class="mt-1 w-8/12">
            <input @keydown.enter="login()" v-model="auth.email" autocomplete="false" placeholder="sample＠sample.jp"
              id="email" class="block w-full appearance-none border border-default px-3 py-2 placeholder-gray-400 shadow-sm focus text-sm" />
          </div>
        </div>

        <div class="flex flex-row items-center gap-4">
          <div class="w-4/12 font-bold text-right text-base">ＰＡＳＳＷＤ</div>
          <div class="mt-1 w-8/12">
            <div class="relative">
              <input @keydown.enter="login()" v-model="auth.password" :type="showPass ? 'text' : 'password'"
                autocomplete="false" required id="password" placeholder="********" role="presentation"
                class="block w-full appearance-none border border-default px-3 py-2 placeholder-gray text-sm" />
              <button type="button" @click="showPass = !showPass"
                class="absolute inset-y-0 right-0 flex items-center pr-3">
                <EyeIcon v-if="showPass" class="h-5 w-5 text-slate-750" aria-hidden="true" />
                <EyeSlashIcon v-else class="h-5 w-5 text-slate-750" aria-hidden="true" />
              </button>
            </div>
            <div class="text-blue-810 text-xs sm:flex cursor-pointer flex justify-end pt-[4px]">
              <router-link to="/forgot-password">{{ $t('login_forgot_password') }}</router-link>
            </div>
          </div>
        </div>        

        <div class="flex flex-row items-center gap-4">
          <div class="w-4/12"></div>
          <div class="w-8/12">
            <button @click="login"
              class="flex w-full justify-center bg-blue-910 py-2 px-4 text-white shadow-sm hover:opacity-80 transition duration-300 font-semibold">
              ｌｏｇｉｎ
            </button>
          </div>          
        </div>
      </div>

    </div>

    <div class="mx-auto text-white pb-4 text-[2rem] font-bold flex flex-row items-center gap-2">
      <img :src="`/images/moto-login-1.png`"/>
      {{ $t('app') }}
      <img :src="`/images/moto-login-1.png`" style="transform: scaleX(-1);"/>
    </div>

  </div>
</template>
