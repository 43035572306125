import axios from "axios";
import { defineStore } from "pinia";

export const TemplatesStore = defineStore('templates-store', {
  state: () => ({
    items: [],
    item: {},
    tempItem: {},
  }),
  getters: {
    isChanged(state) {
      if (JSON.stringify(state.item) !== JSON.stringify(state.tempItem)) {
        return true;
      }
      return false;
    },
  },

  actions: {
    async getList() {
      const response = await axios.get("/api/templates");
      if(response.data.length > 0) {
        this.items = response.data
        this.item = this.items[0]
        this.tempItem = Object.assign({}, this.item)
      }
      
    },

    async save() {
      //put data to api to save
      const response = await axios.post('/api/templates', this.item)
      if(response.data.items.length > 0) {
        this.items = response.data.items
        this.item = this.items.find(item => item.id === response.data.id)
        this.tempItem = Object.assign({}, this.item)
      }
      return response.data
    },
    async changeStatus($data) {
      const response = await axios.post('/api/templates/change-status', {id:$data.id,status:$data.status})
      return response.data
    },

    async updateSort() {
      const response = await axios.post('/api/templates/update-sort', this.items)

      this.items = response.data.items
      this.item = this.items.find(item => item.id === this.item.id)
      this.tempItem = Object.assign({}, this.item)
      return response.data
    }
  }
});