import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const common = {
    arrDayName: ['日', '月', '火', '水', '木', '金', '土'],
    generateKey() {
        return (Date.now().toString(32) + Math.random().toString(16))
            .toString()
            .replace(/\./g, '')
            .replace(/-/g, '')
            .replace(/_/g, '');
    },
    maxFileSize() {
        return 1000000; //5Mb
    },
    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        });
    },
    isValidEmail(email) {
        return /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(email);
    },
    validPassword(value) {
        const containsUppercase = /[A-Z]/.test(value)
        const containsLowercase = /[a-z]/.test(value)
        const containsNumber = /[0-9]/.test(value)
        const containsSpecial = /[#?!@$%^&*-]/.test(value)
        return containsUppercase && containsLowercase && containsNumber && containsSpecial
    },

    formartFullDate(date) {
        if (!date) return ''

        const currentDate = dayjs()
        const _date = dayjs(date)

        if (_date.format('YYYY.MM.DD') == currentDate.format('YYYY.MM.DD')) {
            return _date.format(`HH:mm`)
        }

        const dayName = this.arrDayName[_date.format('d')]
        return _date.format(`YYYY.MM.DD (${dayName}) - HH:mm`)
    },

    boldMatchSearch(text, searchTerm) {
        if (!text || text.length == 0)
            return text

        text = this.convertToEmoji(text)
        // Match <a> tags without target attributes and add target="_blank"
        text = text.replace(/<a\s+(?![^>]*\btarget=["']?_blank["']?)([^>]*?)>/g, '<a target="_blank" $1>');

        if (searchTerm.length == 0)
            return text

        const arrSearch = searchTerm.split(' ').map(str => str.trim()).filter(Boolean);
        if (arrSearch.length === 0) return text;
        const regex = new RegExp(`(${arrSearch.join('|')})`, 'gi');
        text = text.replace(regex, `<b>$1</b>`);
        return text;
    },
    autoContrastColor(color) {
        // Define a threshold value for determining contrast
        const threshold = 128;

        // Convert the color to RGB format
        let r, g, b;
        if (color.startsWith("#") && color.length === 7) {
            r = parseInt(color.substring(1, 3), 16);
            g = parseInt(color.substring(3, 5), 16);
            b = parseInt(color.substring(5, 7), 16);
        } else {
            return '#FFFFFF'
        }

        // Calculate the luminance of the color
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

        // Determine the contrasting color based on the luminance value
        const contrastColor = luminance > (threshold / 255) ? "#000000" : "#ffffff";

        return contrastColor;
    },

    convertToEmoji(inputString) {
        if (!inputString) return inputString
        // Mapping of text patterns to emojis
        const emojiMap = {
            // Add more mappings as needed
            ';-D': '😄',  // Grinning Face
            ":-)": "😊",  // Smiley Face
            ":)": "😊",   // Smiley Face
            ":-(": "😞",  // Sad Face
            ":(": "😞",   // Sad Face
            ";-)": "😉",  // Winking Face
            ";)": "😉",   // Winking Face
            ":-D": "😄",  // Grinning Face
            ":D": "😀",   // Grinning Face with Big Eyes
            ":P": "😋",   // Face Savoring Food
            "<3": "❤️",   // Heart
            ":-o": "😮",  // Surprised Face
            ":o": "😮",   // Surprised Face
            "B-)": "😎",  // Smiling Face with Sunglasses
            ":-|": "😐",  // Neutral Face
            ":-X": "😶‍🌫️", // Face with Finger Covering Mouth
            ":'(": "😢",  // Crying Face
            ":')": "😢",  // Crying Happy Face
            ">:(": "😠",  // Angry Face
            ":-/": "😕",  // Confused Face
            "X(": "😤",   // Angry Face
            ":*": "😘",   // Face Blowing a Kiss
            "O:)": "😇",  // Smiling Face with Halo
            "3:)": "😈",  // Smiling Face with Horns
            "Kappa": "😜",   // Winking Face with Tongue
            "v.v": "😿",  // Crying Cat Face
            "^_^": "😸",  // Grinning Cat Face
            "0:)": "😇",  // Innocent Face
            "t^t": "😩",  // Weary Face
            "Q_Q": "😭",  // Crying Face
            "o(╥﹏╥)o": "😢", // Crying Face
            "/-ok": "👍"    // Thumbs Up
        };

        // Replace all occurrences of the keys in inputString with their corresponding emoji
        for (const [key, value] of Object.entries(emojiMap)) {
            const regex = new RegExp(this.escapeRegExp(key), 'g'); // Create a regex for global replacement
            inputString = inputString.replace(regex, value);
        }
        return inputString
    },
    escapeRegExp(string) {
        return string.replace(/[-\/\\^$.*+?()[\]{}|]/g, '\\$&');
    },
}

export default common