<script setup>
import { ref, onMounted, defineExpose, } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue';

onMounted(async () => {

})

const isOpen = ref(false)
const image = ref(null)
const show = (_image) => {
  image.value = _image
  isOpen.value = true
}
defineExpose({ show })

function closeModal() {
  isOpen.value = false
}
</script>

<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog :open="isOpen" @close="closeModal" class="relative z-50">
      <!-- <div class="fixed inset-0 bg-black/30" aria-hidden="true" /> -->
      <div class="fixed inset-0 flex w-screen items-center justify-center p-4 cursor-zoom-out popup-image">
        <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95">
          <DialogPanel class="flex max-h-[90%] max-w-[90%]" @click="closeModal">
            <div class="flex items-center justify-center   ">
              <img :src="image" class="max-w-full max-h-full border-0" :key="image" />
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<style scoped>
.popup-image{
  background: rgba(1, 1, 1, 0.5)
}
</style>