<script setup>
import { ref, onMounted, getCurrentInstance, defineProps, nextTick } from 'vue'
const $root = getCurrentInstance()?.appContext.config.globalProperties

import Loading from 'views/common/loading.vue'

import common from 'store/common'

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import ChatStatusAction from 'views/icons/ChatStatusAction.vue'

import { ChatsStore } from "store/chats";
const chatsStore = ChatsStore();

import PopupSelectTemplate from './PopupSelectTemplate.vue';
const refPopupSelectTemplate = ref(null)

import WidgetAttachs from './WidgetAttachs.vue';

const props = defineProps({
  room_id: {
    type: Number,
    required: true,
  },
})

onMounted(async () => {
  if (props.room_id > 0) {
    await chatsStore.getMessages(props.room_id)
  }
  recalHeightMain()
})

const loading = ref(false)
const searchMessages = async () => {
  loading.value = true
  await chatsStore.getMessages(props.room_id)
  loading.value = false
}

const changeAction = ($action) => {
  if (chatsStore.room.status == $action) {
    return;
  }
  //update status room
  axios.post(`/api/update-room-status`, { room_id: chatsStore.room.id, status: $action }).then(response => {
    if (response.data.code == 400) {
      $root.notify({ group: 'top', message: t('msg_error'), type: 'error' })
    }
    else {
      chatsStore.room.status = $action
    }
  })

}

const sendMessage = async () => {
  //validate form
  chatsStore.error = {}
  if (!chatsStore.message.message) {
    chatsStore.error.message = true
    return
  }

  $root.$overlay.show()
  //check image and upload first
  if (commentAttachs.value.length > 0) {
    await axios.post("/api/uploadAttachs", { attachs: commentAttachs.value, folder: 'message', type: 'base64' })
      .then((response) => {
        if (response.data.length > 0) {
          chatsStore.message.attachs = response.data
          commentAttachs.value = []
        }
      })
      .catch((err) => {
        console.log(err)
        $root.$overlay.hide();
        $root.notify({ group: 'top', message: t('msg_error'), type: 'error' })
        return;
      });
  }

  //save message
  await chatsStore.saveMessage().then(response => {
    if (response.code == 200) {
      $root.notify({ group: 'top', message: '送信しました。', type: 'success' })

      //reset form
      chatsStore.message = Object.assign({}, JSON.parse(JSON.stringify(chatsStore.intMessage)));

      //reload call comment
      chatsStore.getMessages(props.room_id)

      //scroll to botton widget message
      setTimeout(() => {
        document.getElementById('chat-main-messages').lastElementChild.scrollIntoView({ behavior: 'smooth' });
      }, 2000)
    }
    else {
      $root.notify({ group: 'top', message: t('msg_error'), type: 'error' })
    }
    $root.$overlay.hide();
  })
    .catch((err) => {
      console.log(err)
      $root.$overlay.hide();
      $root.notify({ group: 'top', message: t('msg_error'), type: 'error' })
      return;
    });

  //recall height message block
  recalHeightMain()
}
const selectTemplate = () => {
  const clickedElement = document.getElementById(`select-template`)
  const { left, top } = clickedElement.getBoundingClientRect();
  refPopupSelectTemplate.value.show({ left: left, top: top })
}
const refAddFiles = ref(null)
const commentAttachs = ref([])
const addFiles = async (attachs) => {
  let overSize = false
  for (let i = 0; i < attachs.target.files.length; i++) {
    const attach = attachs.target.files[i]

    if (attach.size > common.maxFileSize()) {
      overSize = true
    }
    else {
      let _data = {}
      _data.name = attach.name
      _data.size = attach.size
      _data.type = attach.type
      _data.ext = attach.name.split('.').pop()
      _data.img = ['jpg', 'jpeg', 'png', 'bmp'].includes(_data.ext)

      if (chatsStore.room.type == 'line') {
        if (_data.img == true) {
          await common.getBase64(attach).then((data) => _data.file = data)
          commentAttachs.value.push(_data)
        }
      }
      else if (chatsStore.room.type == 'zalo') {
        if (_data.img == true || ['pdf', 'doc', 'docx'].includes(_data.ext)) {
          await common.getBase64(attach).then((data) => _data.file = data)
          commentAttachs.value.push(_data)
        }
      }
      else {
        await common.getBase64(attach).then((data) => _data.file = data)
        commentAttachs.value.push(_data)
      }

    }
  }
  if (overSize) {
    $root.notify({ group: 'top', message: '1MB未満の画像を使ってください。', type: 'error' })
  }
  //recall height message block
  recalHeightMain()
}

const removeFile = (idx) => {
  commentAttachs.value.splice(idx, 1)

  //reset height message block
  recalHeightMain()
}
const recalHeightMain = () => {
  const winHeight = window.innerHeight
  const chatHead = document.getElementById(`chat-main-head`).offsetHeight
  const chatNewMessage = document.getElementById(`chat-main-new-message`).offsetHeight
  nextTick(() => {
    const chatNewattachs = document.getElementById(`chat-new-attachs`).offsetHeight
    if (commentAttachs.value.length == 0) {
      document.getElementById('chat-main-messages').style.maxHeight = (winHeight - chatHead - chatNewMessage) + 'px'
      document.getElementById('chat-main-messages').style.height = (winHeight - chatHead - chatNewMessage) + 'px'
    }
    else {
      document.getElementById('chat-main-messages').style.height = (winHeight - chatHead - chatNewMessage - chatNewattachs) + 'px'
      document.getElementById('chat-main-messages').style.maxHeight = (winHeight - chatHead - chatNewMessage - chatNewattachs) + 'px'
    }

    //scroll to botton widget message
    document.getElementById('chat-main-messages').lastElementChild.scrollIntoView({ behavior: 'smooth' });
  })
}
</script>
<template>
  <Loading :loading="loading" />
  <div v-if="chatsStore.room">
    <div class="flex flex-row justify-between p-4 drop-shadow bg-white lg:h-[60px]" id="chat-main-head">
      <div class="gap-2 flex flex-row items-center px-4">
        <div><img :src="`images/logo_${chatsStore.room.type}.png`" class="h-8 min-h-8" /></div>
        <div class=" line-clamp-1"
          v-html="chatsStore.info.name_fa ? common.convertToEmoji(chatsStore.info.name_fa) : chatsStore.info.name">
        </div>
      </div>
      <div class="gap-4 flex flex-row items-center">
        <button class="btn-action  flex flex-row items-center gap-1 rounded hover:opacity-80"
          :class="{ 'active': chatsStore.room.status == 'action' }" @click="changeAction('action')">
          <ChatStatusAction :active="chatsStore.room.status == 'action'" />要対応
        </button>
        <button class="btn-acknowledged flex flex-row items-center gap-1 rounded hover:opacity-80"
          :class="{ 'active': chatsStore.room.status == 'acknowledged' }" @click="changeAction('acknowledged')">
          <Icon icon="ic:outline-check-circle" height="16px" v-if="chatsStore.room.status != 'acknowledged'"></Icon>
          <Icon icon="icon-park-solid:check-one" height="16px" v-if="chatsStore.room.status == 'acknowledged'"></Icon>
          対応済み
        </button>
        <div>
          <input type="search" v-model.trim="chatsStore.filterMessages.search" @keydown.enter="searchMessages()"
            @change="searchMessages()" class="rounded h-7 border px-2 w-full border-default outline-none"
            placeholder="検索" />
        </div>
      </div>
    </div>

    <!-- block message -->
    <div class="flex flex-col px-2 py-4 gap-4 wap-message" id="chat-main-messages">
      <template v-if="chatsStore.messages.length > 0" v-for="message in chatsStore.messages">
        <div class="w-full flex flex-row gap-2 justify-start" v-if="message.admin == 0">
          <div class="min-w-9 h-6 w-6 rounded-md">
            <img :src="message.avatar ? `${message.avatar}` : 'images/avata-default.png'" class="avatar circle" />
          </div>
          <div class="gap-1">
            <template v-if="message.message">
              <div
                class="rounded-lg rounded-tl-[0px] bg-[#E7ECF0] py-2 px-4 text-sm text-[#333333] whitespace-pre-line break-all"
                v-html="common.boldMatchSearch(message.message, chatsStore.filterMessages.search)"></div>
            </template>
            <div class="pt-1 flex gap-2">
              <div class="flex flex-row gap-2 flex-wrap">
                <WidgetAttachs :attachs="message.attachs" v-if="message.attachs.length > 0" />
              </div>
            </div>
          </div>
          <div class="flex items-end date-time min-w-[125px]">{{ common.formartFullDate(message.created_at) }}</div>
        </div>

        <div class="w-full flex gap-2 justify-end" v-else>
          <div class="h-full flex items-end min-w-[125px]">
            <div class="w-full text-end date-time">
              <!-- <div>既読</div> -->
              <div class="">{{ common.formartFullDate(message.created_at) }}</div>
            </div>
          </div>
          <div class="">
            <template v-if="message.message">
              <div
                class="rounded-lg rounded-tr-[0px] bg-violet-250 py-2 px-4 text-sm text-[#333333] whitespace-pre-line break-all"
                v-html="common.boldMatchSearch(message.message, chatsStore.filterMessages.search)"></div>
            </template>
            <div class="pt-1 flex gap-2">
              <div class="flex flex-row gap-2 flex-wrap">
                <WidgetAttachs :attachs="message.attachs" v-if="message.attachs.length > 0" />
              </div>
            </div>
          </div>
        </div>

      </template>

      <div id="lastest_message"></div>
    </div>

    <!-- block attach files-->
    <div class="relative  max-h-[208px] overflow-auto bg-slate-100" id="chat-new-attachs">
      <div class="py-2 px-4 border-t" v-if="commentAttachs.length > 0">
        <div class="flex flex-row gap-2 flex-wrap">
          <template v-for="(attach, idxf) in commentAttachs">
            <div class="h-24 w-24 bg-gray-700 rounded-md text-white">
              <div class="flex flex-col justify-between img-preview border rounded-md cursor-zoom-in"
                :style="{ 'background-image': 'url(' + attach.file + ')' }">
                <div class="flex justify-end">
                  <div class="bg-[#00000080] group">
                    <Icon icon="mingcute:close-fill" width="20px" @click="removeFile(idxf)"
                      class=" group-hover:scale-120 hover:text-rose-500 transition-all delay-75 duration-300 ease-out cursor-pointer">
                    </Icon>
                  </div>
                </div>
                <div class="flex justify-center font-logo uppercase">
                  <span v-if="!attach.img" class=" bg-black px-2">{{ attach.ext }}</span>
                </div>
                <div class="px-1">
                  <div class="text-xs line-clamp-2 text-slate-100" v-if="!attach.img" :title="attach.name">{{
    attach.name }}</div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- block new message -->
    <div class="border-t p-4 relative" id="chat-main-new-message">
      <div class="flex justify-between bg-[#F3F5F7] border border-default border-b-0  text-[13px]">
        <div class="flex text-blue-910">
          <button @click="refAddFiles.click()"
            class="flex flex-row items-center gap-2 px-2 hover:text-yellow-650 hover:bg-[#FEFBF2] active:bg-[#f8ebc6]">
            <Icon icon="mingcute:attachment-line" class="text-xl"></Icon>
            <input type="file" ref="refAddFiles" multiple @change="addFiles" class="hidden" />
            <span>ファイル送信</span>
          </button>

          <button @click="selectTemplate" id="select-template"
            class="flex flex-row items-center gap-2 px-2 hover:text-yellow-650 hover:bg-[#FEFBF2] active:bg-[#f8ebc6]">
            <Icon icon="majesticons:note-text-plus-line" class="text-lg"></Icon>
            <span>定型文</span>
          </button>
        </div>

        <div class="flex gap-2 items-center">
          <div>{{ chatsStore.message.message.length }}/3000</div>
          <div>
            <button @click="sendMessage" :disable="true" :class="{
    ' cursor-not-allowed': chatsStore.message.message.length == 0,
    'bg-[#FEFBF2] text-blue-810': chatsStore.message.message.length > 0,
  }" class="flex flex-row items-center gap-2 px-2 hover:text-blue-810 hover:bg-[#FEFBF2] act-send active:bg-[#f8ebc6]">
              <Icon icon="fluent:send-28-regular" class="text-lg" v-if="chatsStore.message.message.length == 0"></Icon>
              <Icon icon="fluent:send-28-filled" class="text-lg" v-else></Icon>
              <span>送信</span>
            </button>
          </div>
        </div>
      </div>
      <textarea v-model.trim="chatsStore.message.message"
        :class="chatsStore.error.message ? 'border-red-500' : 'border-default'" maxlength="3000"
        class="p-2 w-full h-[120px] border rounded-b-lg resize-none placeholder:text-gray-400 placeholder:text-sm !outline-none"
        inputmode="text" placeholder="メッセージを入力してください。"></textarea>
    </div>

    <PopupSelectTemplate ref="refPopupSelectTemplate" />

  </div>
</template>

<style scoped>
.wap-message {
  max-height: calc(100vh - 185px - 60px);
  overflow-y: auto;
}

button[class*="btn-"] {
  font-size: 14px;
  padding: 4px;
}

.btn-action {
  border: 1px solid #3D407D;
  color: #3D407D;
}

.btn-action.active {
  border: 1px solid #ED9C06;
  color: #ED9C06;
}

.btn-acknowledged {
  border: 1px solid #3D407D;
  color: #3D407D;
}

.btn-acknowledged.active {
  border: 1px solid #1976D2;
  color: #1976D2;
}
</style>