<script setup>
import { ref, defineExpose, getCurrentInstance } from 'vue'
const $root = getCurrentInstance()?.appContext.config.globalProperties

import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const isOpenDialog = ref(false)

import { ChatsStore } from "store/chats";
const chatsStore = ChatsStore();

let pTop = ref(200)
const show = ($memo = {}, position) => {
  isOpenDialog.value = true
  chatsStore.memo = Object.assign({}, $memo)
  pTop.value = position.top
}
defineExpose({ show })

const deleteMemo = () => {
  if (chatsStore.memo.id > 0) {
    $root.$overlay.show()
    chatsStore.deleteCustomerMemo().then(response => {
      if (response.code == 200) {
        $root.notify({ group: 'top', message: t('msg_success'), type: 'success' })
      }
      else {
        $root.notify({ group: 'top', message: t('msg_save_error'), type: 'error' })
      }
      isOpenDialog.value = false
      $root.$overlay.hide()
    })
      .catch((err) => {
        $root.$overlay.hide();
        isOpenDialog.value = false
        $root.notify({ group: 'top', message: t('msg_save_error'), type: 'error' })
        console.log(err);
      });
  }
}

</script>
<template>
  <TransitionRoot appear :show="isOpenDialog" as="template">
    <Dialog as="div" class="relative z-10">
      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel :style="{ top: pTop }"
              class="w-full max-w-[328px] transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all absolute right-[40px] border-t-4 border-red-500">
              <div class="flex flex-col py-6 px-4 gap-4">
                <div class=" text-red-500 font-bold text-base">削除確認</div>
                <div class="text-sm">削除後はメモを元に戻すことはできません。</div>
                <div class=" text-center w-full font-bold text-base">本当にこのメモを削除しますか？</div>

                <div class=" flex mx-auto justify-center gap-6">
                  <button type="button" class="btn-delete shadow" @click="deleteMemo">削除する</button>

                  <button type="button" class="btn-cancel shadow" @click="isOpenDialog = false">キャンセル</button>
                </div>
              </div>

            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped></style>