<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  class: {
    type: String,
    default: '',
  },
})
</script>
<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :class="props.class">
    <path d="M3 6H13V8H3V6ZM3 16H13V18H3V16ZM3 11H15V13H3V11ZM16 7L14.58 8.39L18.14 12L14.58 15.61L16 17L21 12L16 7Z"/>
  </svg>
</template>
