<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  class: {
    type: String,
    default: '',
  },
  active: {
    type: Boolean,
    default: false
  }
})
</script>
<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :class="props.class">
    <path d="M11.3333 12.3333C10.05 12.3333 8.95139 11.8764 8.0375 10.9625C7.12361 10.0486 6.66667 8.95 6.66667 7.66667C6.66667 6.38333 7.12361 5.28472 8.0375 4.37083C8.95139 3.45694 10.05 3 11.3333 3C12.6167 3 13.7153 3.45694 14.6292 4.37083C15.5431 5.28472 16 6.38333 16 7.66667C16 8.95 15.5431 10.0486 14.6292 10.9625C13.7153 11.8764 12.6167 12.3333 11.3333 12.3333ZM2 19.3333V18.4C2 17.7583 2.16528 17.1556 2.49583 16.5917C2.82639 16.0278 3.28333 15.6 3.86667 15.3083C4.85833 14.8028 5.97639 14.375 7.22083 14.025C8.46528 13.675 9.83611 13.5 11.3333 13.5H11.7417C11.8583 13.5 12 13.5 12 13.5C12 13.5 14.6292 13.3941 14.6292 14.8915C14.6292 15.9694 11.5749 15.8333 11.45 15.8333H11.3333C9.95278 15.8333 8.71339 16.0083 7.61517 16.3583C6.51694 16.7083 5.61744 17.0583 4.91667 17.4083C4.74167 17.5056 4.6005 17.6417 4.49317 17.8167C4.38583 17.9917 4.33256 18.1861 4.33333 18.4V19.3333H12.3512C12.9818 19.3333 13.6667 19.8488 13.6667 20.5C13.6667 21.1512 12.9021 21.6667 12.3512 21.6667H4.33333C3.69167 21.6667 3.14256 21.4384 2.686 20.9818C2.22944 20.5253 2.00078 19.9758 2 19.3333ZM11.3333 10C11.975 10 12.5245 9.77172 12.9818 9.31517C13.4392 8.85861 13.6674 8.30911 13.6667 7.66667C13.6667 7.025 13.4384 6.47589 12.9818 6.01933C12.5253 5.56278 11.9758 5.33411 11.3333 5.33333C10.6917 5.33333 10.1426 5.562 9.686 6.01933C9.22944 6.47667 9.00078 7.02578 9 7.66667C9 8.30833 9.22867 8.85783 9.686 9.31517C10.1433 9.7725 10.6924 10.0008 11.3333 10Z" :fill="active ? '#ED9C06': '#FEFBF2'"/>
    <path d="M18.531 21C18.0006 21 17.4919 20.7893 17.1168 20.4142C16.7417 20.0391 16.531 19.5304 16.531 19C16.531 18.4696 16.7417 17.9609 17.1168 17.5858C17.4919 17.2107 18.0006 17 18.531 17M18.531 21C19.0614 21 19.5701 20.7893 19.9452 20.4142C20.3203 20.0391 20.531 19.5304 20.531 19C20.531 18.4696 20.3203 17.9609 19.9452 17.5858C19.5701 17.2107 19.0614 17 18.531 17M18.531 21V22.5M18.531 17V15.5M21.562 17.25L20.263 18M16.8 20L15.5 20.75M15.5 17.25L16.8 18M20.263 20L21.563 20.75" :stroke="active ? '#ED9C06': '#FEFBF2'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
