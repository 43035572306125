<script setup>
import {ref, defineExpose, getCurrentInstance} from 'vue'
const $root = getCurrentInstance()?.appContext.config.globalProperties

import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import { UsersStore } from "store/users";
const usersStore = UsersStore();

const isOpenDialog = ref(false)

const show = ($value=true) => {
  isOpenDialog.value = $value
}
defineExpose({ show })

const saveNewUser = async () => {
  usersStore.validateUser()

  if(Object.entries(usersStore.error).length > 0){
    return;
  }

  $root.$overlay.show();
  usersStore.saveNewUser().then((response) => {
    if(response.error && response.error.email == true){
      usersStore.error.email = true
      $root.$overlay.hide();
      return;
    }

    if(response.success == true){
      $root.notify({group: 'top', message: t('msg_success'), type: 'success'})
    }
    else {
      $root.notify({group: 'top', message: t('msg_error'), type: 'error'})
    }
    $root.$overlay.hide();
    isOpenDialog.value = false

    //get list again
    usersStore.getList()

    //reset popup create value
    usersStore.user = {}
  })
  .catch((err) => {
    console.log(err)
    $root.$overlay.hide();
  });
}
</script>
<template>
  <TransitionRoot appear :show="isOpenDialog" as="template">
      <Dialog as="div" class="relative z-10">
        <div class="fixed inset-0 overflow-y-auto">
          <div class="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95">
              <DialogPanel
                class="w-full max-w-[328px] transform overflow-hidden bg-white p-5 text-left align-middle shadow-xl transition-all rounded">
                <div class="flex mt-2 justify-between border-b-2 pb-2 border-blue-800">
                  <div class="text-[#33333] text-lg font-bold">新規登録招待</div>
                  <div>
                    <Icon icon="mingcute:close-fill" width="24px" class="text-lg text-blue-800 hover:scale-150 transition-all delay-75 duration-300 ease-out cursor-pointer" @click="isOpenDialog = false"></Icon>
                  </div>
                </div>
                <div class="flex flex-col gap-y-5">

                  <div class="text-blue-800 text-sm pt-5">
                    新規追加と招待URL付メールを送信します。
                  </div>

                  <div class="">
                    <div class="flex flex-row gap-1">
                      <span class="text-sm">表示名</span>
                      <span class="text-require">※必須</span>
                    </div>
                    <div>
                      <input v-model="usersStore.user.name" :class="usersStore.error.name == true ? 'border-red-500' : 'border-default'"
                        id="name"
                        class="block w-full appearance-none rounded border px-3 py-1 placeholder-gray-400 shadow-sm focus"
                        placeholder="名前" />
                    </div>
                  </div>

                  <div class="">
                    <div class="flex flex-row gap-1">
                      <span class="text-sm">メールアドレス</span>
                      <span class="text-require">※必須</span>
                    </div>
                    <div>
                      <input v-model="usersStore.user.email" :class="usersStore.error.email == true ? 'border-red-500' : 'border-default'"
                        id="name"
                        class="block w-full appearance-none rounded border px-3 py-1 placeholder-gray-400 shadow-sm focus"
                        placeholder="sample＠sample.jp" />
                    </div>
                  </div>

                </div>
                

                <div class="mt-8 flex mx-auto justify-center gap-4">
                  <button type="button" class=" bg-blue-810 text-white rounded shadow px-4 py-2 hover:opacity-80 font-bold"
                    @click="saveNewUser">追加・招待メール送信</button>                  
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
</template>

<style scoped>
.text-require{
  font-size: 13px;
  color: #E63946;
  font-weight: bold;
}
</style>