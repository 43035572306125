<script setup>
import {ref, onMounted, getCurrentInstance} from 'vue'
const $root = getCurrentInstance()?.appContext.config.globalProperties

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import { Switch } from '@headlessui/vue'

import { UsersStore } from "store/users";
const usersStore = UsersStore();

import { AuthStore } from "store/auth";
const authStore = AuthStore();

import PopupNewAccount from './PopupNewAccount.vue'
const refPopupNewAccount = ref(null)

onMounted(() => {
  //get list user
  usersStore.getList()
  
})
const newRow = () => {
  usersStore.users.push({
    id: 0,
    name: "",
    email: "",
    enable: true,
  })
}
const actDelete = async ($id, idx) => {
  if(authStore.user.id == $id) {
    return;
  }
  
  $root.$overlay.show();
  if($id == 0){
    usersStore.users.splice(idx, 1)
  }
  else {
    if(confirm('本当に削除しますか- ')) {
      const response = await usersStore.delete($id)
      if(response.success == true){
        $root.notify({group: 'top', message: t('msg_success'), type: 'success'})
      }
      else{
        $root.notify({group: 'top', message: t('msg_error'), type: 'error'})
      }
    }
  }
  
  $root.$overlay.hide()
}

const actSave = async() => {
  //validate
  await usersStore.validateUsers()
  let check = false      
  usersStore.errors.forEach((error) => {
    if(error && (error.name || error.email)){
      check = true
    }
  })
  if(check == true){
    return false
  }

  $root.$overlay.show();
  const response = await usersStore.saveAll()
  let message = ''
  let type = ''
  if(response == false){
    message = t('msg_error')
    type = 'error'
  }
  else{
    message = t('msg_success')
    type = 'success'
  }
  $root.notify({group: 'top', message: message, type: type})
  if(response.reload == true){
    setTimeout(() => {location.reload()}, 4000)
  }
  $root.$overlay.hide()
}
</script>
<template>
  <div class="flex flex-col mx-auto w-[680px] lg:py-16">

    <div class="flex items-center justify-between w-full py-4">
      <div class="text-2xl font-bold text-blue-910">アカウント</div>
      <div>
        <button @click="refPopupNewAccount.show()"
          class="flex w-full justify-center rounded bg-blue-810 py-2 px-4 font-bold text-white shadow-sm hover:opacity-80 tracking-widest">
          新規ID追加・招待
        </button>
      </div>
    </div>

    <div class="w-full scroll-smooth touch-auto overflow-auto hover:scroll-auto border-stone-300 rounded border">
      <table class="bg-white w-full border-none custom">
        <thead class="border-b text-black h-8 bg-slate-150 justify-start font-normal">
          <th class="text-left" style="width: 180px;">表示名</th>
          <th class="text-left">メールアドレス（ログインID）</th>
          <th class="text-center" style="width: 100px;">有効/無効</th>
          <th class="text-center" style="width: 100px;">削除</th>           
        </thead>
        <tbody>
          <tr v-for="(user,idx) in usersStore.users">            
            <td style="width: 180px;">
              <input v-model.trim="user.name" :id="`${idx}_${user.name}`" 
              :maxlength="100"
              :class="(usersStore.errors[idx] && usersStore.errors[idx].name == true) ? 'border-red-500' : 'border-default'"
              class="block w-full rounded appearance-none border  px-3 placeholder-gray-400 shadow-sm focus text-sm h-8" />
            </td>
            <td class="">
              <input v-model.trim="user.email" :id="`${idx}_${user.email}`" 
              :maxlength="150"
              :class="(usersStore.errors[idx] && usersStore.errors[idx].email == true) ? 'border-red-500' : 'border-default'"
              class="block w-full rounded appearance-none border px-3 placeholder-gray-400 shadow-sm focus text-sm h-8" />
            </td>
            <td class="text-center" style="width: 100px;">
              <Switch
                v-model="user.enable"
                v-if="authStore.user.id != user.id"
                :class="user.enable ? 'bg-green-300' : 'bg-slate-800'"
                class="relative inline-flex h-7 w-12 items-center rounded-full"
              >
                <span
                  :class="user.enable ? 'translate-x-6' : 'translate-x-1'"
                  class="inline-block h-4 w-4 transform rounded-full bg-white transition"
                />
              </Switch>
            </td>
            <td class="text-center" style="width: 100px;">              
                <button @click="actDelete(user.id, idx)"
                  :disable="usersStore.users.length <= 1 || authStore.user.id == user.id"
                  :class="usersStore.users.length <= 1 || authStore.user.id == user.id ? 'cursor-not-allowed bg-gray-410' : 'cursor-pointer bg-red-550'"
                  class="flex w-full items-center justify-center rounded px-4 font-bold text-white shadow-sm hover:opacity-80 h-7">
                  削除
                </button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <td colspan="4" class="text-center">
            <div class="w-full flex justify-center">
              <button @click="actSave"
                class="btn-save shadow">
                保存
              </button>
            </div>
            
          </td>
        </tfoot>
      </table>
    </div>
    <PopupNewAccount ref="refPopupNewAccount" />
  </div>

</template>
