<script setup>
import { ref, defineExpose, onMounted } from 'vue'

import { TransitionRoot, TransitionChild, Dialog, DialogPanel, Tab } from '@headlessui/vue'

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const isOpenDialog = ref(false)

import { ChatsStore } from "store/chats";
const chatsStore = ChatsStore();

import { TemplatesStore } from "store/templates";
const templatesStore = TemplatesStore();

const templates= ref([])
let position = ref({left:100, top: 200})
const show = async (_position) => {
  //set position popup template
  position.value.top = (_position.top - 415)+'px'
  position.value.left = (_position.left - 250)+'px'

  isOpenDialog.value = true

  //check and get template
  if(templatesStore.items.length == 0){
   await templatesStore.getList()
  }
  templates.value = templatesStore.items.filter(item=> item.status == true)

}
defineExpose({ show })

const selectTemplate = (temp) => {
  chatsStore.message.message = temp.content
  isOpenDialog.value = false
}
</script>
<template>
  <TransitionRoot appear :show="isOpenDialog" as="template">
    <Dialog as="div" @close="(isOpenDialog = false)" class="relative z-10">
      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              :style="{ top: position.top, left:position.left }"
              class="w-full popup-template transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all absolute rounded-lg border-2 border-blue-910">
              <div class="flex flex-col p-4 gap-2">
                <div class="bg-[#F3F5F7] rounded px-4 py-2 text-blue-910 h-9 flex justify-between">
                  <div class="font-bold">定型文選択</div>
                </div>

                <div class="flex flex-col overflow-y-auto h-[340px] max-h-[340px] border border-default">
                  <template v-if="templates.length > 0" v-for="(temp, tIdx) in templates" :key="tIdx">
                    <div class="hover:bg-violet-250 cursor-pointer active:opacity-80" @click="selectTemplate(temp)">
                      <div class="text-blue-910 text-sm font-bold mx-2 border-b border-gray-400 pt-2">{{ temp.title }}</div>
                      <div class="border-b border-gray-400 line-clamp-2 text-sm px-2 whitespace-pre-line">
                        {{ temp.content }}
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="flex flex-row items-center justify-center h-full text-blue-800">
                      <router-link to="/templates" class=" hover:underline"> 最初に設定テンプレートに移動します:  定型文設定</router-link>
                    </div>
                    
                  </template>
                  
                </div>
              </div>
              
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
.popup-template{
  max-width: 500px;
  width: 500px;

  height: 415px;
  max-height: 415px;
}
</style>