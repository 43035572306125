import axios from "axios";
import { defineStore } from "pinia";

export const AuthStore = defineStore('auth-store', {
  state: () => ({
    user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
    users: [],

  }),

  actions: {
    async setUser(user) {
      this.user = user;
      if (user) {
        localStorage.setItem("user", JSON.stringify(user));
      } else {
        localStorage.removeItem("user");
      }
    },
    async login(email, password) {
      await axios.get("/sanctum/csrf-cookie");
      const response = await axios.post("/api/login", {email,password,});
      if (response.data.user) {
        this.setUser(response.data.user);
        if (response.data.user.token) {
          // set token for get requests
          window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.user.token;
        }
      }
      return response.data;
    },
    async logout() {
      const response = await axios.post('/api/logout',{})
      // clear local storage
      localStorage.clear()
      this.user = {}
      return response.data
    },
    async forgotPassword(email) {
      if (!email ) {return}

      const response = await axios.post(`/api/forgotPassword`, { email: email});
      return response.data;
    },
  }
});