<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'
const isDragging = ref(false)

const $root = getCurrentInstance()?.appContext.config.globalProperties
import ArrowLeftDouble from 'views/icons/ArrowLeftDouble.vue';

import { Switch } from '@headlessui/vue'

import PopupResetChange from 'views/common/PopupResetChange.vue';
const refPopupResetChange = ref(null)

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import { TemplatesStore } from "store/templates";
const templatesStore = TemplatesStore();

const tempName = ref('')
const disableCreate = ref(false)
const tabId = ref(null)
const error = ref({})
onMounted(async () => {
  //get list template
  await templatesStore.getList()

  if (templatesStore.items.length > 0) {
    tabId.value = templatesStore.items[0].id
  }

})

const createNew = ref(false)
const addTemplate = () => {
  if (tempName.value) {
    //check before add 
    if (templatesStore.isChanged) {
      //show alert change
      refPopupResetChange.value.show(true)
      createNew.value = true
      return
    }

    const _sortd = templatesStore.items.reduce((max, item) => {
        return Math.max(max, item.sort);
    }, 0) + 1
    

    templatesStore.item = { id: 0, title: tempName.value, content: '', status: true, sort: _sortd}
    templatesStore.items.push(templatesStore.item)
    tempName.value = ''
    disableCreate.value = true
    tabId.value = 0
    createNew.value = false
    error.value = {};
  }
}

const tempTabId = ref(null)
const changeTemp = (_id) => {
  if (_id == templatesStore.item.id) {
    return
  }
  tempTabId.value = _id

  //check before change 
  if (templatesStore.isChanged) {
    //show alert change
    refPopupResetChange.value.show(true)

    return
  }
  else {
    templatesStore.item = templatesStore.items.find(item => item.id === _id)
    templatesStore.tempItem = Object.assign({}, templatesStore.item)
    tabId.value = _id
  }

}
const forceChange = () => {
  refPopupResetChange.value.show(false)
  //remove id = 0
  if (templatesStore.item.id == 0) {
    templatesStore.items = templatesStore.items.filter(item => item.id !== 0)
  }
  //if id > 0; Reassign the old value to the array and assign item to the new object
  disableCreate.value = false

  const idx = templatesStore.items.findIndex(item => item.id === tabId.value)
  if (idx >= 0) {
    templatesStore.items[idx] = Object.assign({}, templatesStore.tempItem)
  }

  templatesStore.item = templatesStore.items.find(item => item.id === tempTabId.value)
  templatesStore.tempItem = Object.assign({}, templatesStore.item)

  tabId.value = tempTabId.value

  //In case: user is editing and adding a new template
  if (createNew.value == true) {
    templatesStore.item = {}
    templatesStore.tempItem = {}
    addTemplate()
  }
}

const changeStatus = (idx) => {
  templatesStore.items[idx].status = (templatesStore.items[idx].status == true) ? false : true
  //ignore with id equal to 0
  if (templatesStore.items[idx].id == 0) {
    return
  }

  $root.$overlay.show()
  templatesStore.changeStatus(templatesStore.items[idx]).then((res) => {
    if (res.code === 200) {
      $root.notify({ group: 'top', message: t('msg_success'), type: 'success' })
    } else {
      $root.notify({ group: 'top', message: t('msg_save_error'), type: 'error' })
    }
    $root.$overlay.hide()

    if (templatesStore.tempItem.id == templatesStore.items[idx].id) {
      templatesStore.tempItem.status = templatesStore.items[idx].status
    }
  })

}
const saveTemplate = () => {
  checkForm()
  if (Object.keys(error.value).length > 0) {
    let _msg = ''
    if (error.value.title == true) {
      _msg += '<p>設定名を入力してください</p>'
    }
    if (error.value.content == true) {
      _msg += '<p>内容を入力してください</p>'
    }
    $root.notify({ group: 'top', message: _msg, type: 'error' })
  }
  else {
    //save function here
    $root.$overlay.show()
    templatesStore.save(templatesStore.item).then((res) => {
      if (res.code === 200) {
        $root.notify({ group: 'top', message: t('msg_success'), type: 'success' })

        tabId.value = res.id
      } else {
        $root.notify({ group: 'top', message: t('msg_save_error'), type: 'error' })
      }
      $root.$overlay.hide()
      disableCreate.value = false
    })
  }

}

const checkForm = () => {
  error.value = {};
  if (!templatesStore.item.title) {
    error.value.title = true
  }

  if (!templatesStore.item.content) {
    error.value.content = true
  }

}

const updateSort = () => {
  $root.$overlay.show()
  templatesStore.updateSort().then((res) => {
    if (res.code === 200) {
      $root.notify({ group: 'top', message: t('msg_success'), type: 'success' })
    } else {
      $root.notify({ group: 'top', message: t('msg_save_error'), type: 'error' })
    }
    $root.$overlay.hide()
  })
}
</script>

<template>
  <div class="flex flex-col mx-auto w-full min-h-screen  lg:py-16">

    <div class="max-w-6xl overflow-hidden w-full lg:w-10/12 mx-auto lg:flex-row">
      <div class="w-full flex flex-row text-2xl font-bold text-blue-910 px-4 py-2">定型文設定</div>
      <div class="w-full flex gap-5">
        <div class="w-full lg:w-5/12 flex flex-col gap-4">
          <div class="flex flex-col w-full rounded border border-stone-300 bg-white drop-shadow">
            <div class="px-4 title-label border-b rounded-t-4 bg-slate-150 border-slate-400">
              設定名
            </div>
            <div class="px-4 py-2 flex items-center gap-4">
              <div class="grow">
                <input type="text" class="rounded border border-stone-300 py-1 px-2 w-full h-7 font-normal"
                placeholder="見出し"
                  v-model.trim="tempName" :disabled='disableCreate' :maxlength="255" />
              </div>
              <div class="flex-none">
                <button type="button" class="rounded text-white hover:opacity-80 w-full drop-shadow px-4 py-2"
                  :class="disableCreate || !tempName ? 'cursor-not-allowed bg-gray-410' : 'cursor-pointer bg-blue-810'"
                  @click="addTemplate()" :disabled='disableCreate'>新規追加</button>
              </div>
            </div>
          </div>

          <div class="flex flex-col w-full rounded border border-stone-300 bg-white last:border-b-0">
            <table class="w-full custom">
              <thead class="title-label border-b rounded-t-4 bg-slate-150 border-slate-400">
                <tr>
                  <th class="text-left w-[90px]">有効/無効</th>
                  <th class="text-left">設定名</th>
                  <th class="w-8"></th>
                </tr>
              </thead>
              <tbody class="max-h-[550px]">
                <VueDraggableNext class="flex flex-col" v-model="templatesStore.items" item-key="sort"
                  :disabled="templatesStore.isChanged"
                  @start="isDragging = true" @end="isDragging = false" @change="updateSort">
                  <transition-group>
                    <tr class="border-b border-default" v-for="(item, idx) in templatesStore.items" :key="idx">
                      <td class="w-[90px] py-1">
                        <Switch @click="changeStatus(idx)"
                          :class="item.status == true ? 'bg-green-300' : 'bg-slate-800'"
                          class="relative inline-flex h-7 w-12 items-center rounded-full">
                          <span :class="item.status == true ? 'translate-x-6' : 'translate-x-1'"
                            class="inline-block h-4 w-4 transform rounded-full bg-white transition" />
                        </Switch>

                      </td>
                      <td class="">
                        <a href="#" class="text-blue-600" v-text="item.title" :title="item.title"
                          :class="(templatesStore.item.id == item.id) ? 'font-bold text-blue-810' : ''"
                          @click="changeTemp(item.id)"></a>
                      </td>
                      <td class="text-end w-10">
                        <ArrowLeftDouble v-if="templatesStore.item.id == item.id" class="w-7 h-7" />
                      </td>
                    </tr>
                  </transition-group>
                </VueDraggableNext>
              </tbody>
            </table>
          </div>
        </div>
        <div class="w-full lg:w-7/12">
          <div class="flex flex-col divide-y rounded border border-stone-300">
            <div
              class="flex w-full bg-stone-100 items-center p-2 justify-center gap-3 rounded-t-4 border-b border-slate-400">
              <div>設定名</div>
              <div>
                <input type="text" class="rounded border px-2 min-w-[310px]"
                  :disabled="templatesStore.item.id == undefined" v-model.trim="templatesStore.item.title"
                  placeholder="見出し"
                  :class="error.title ? 'border-red-500' : 'border-stone-300'" @change="error.title = false"
                  :maxlength="255" />
              </div>
            </div>
            <div class="p-2 bg-white">
              <textarea class="p-2 w-full email border border-dashed min-h-64"
                :disabled="templatesStore.item.id == undefined" v-model.trim="templatesStore.item.content"
                :class="error.content ? 'border-red-500' : ''" @change="error.content = false"></textarea>
            </div>
            <div class="p-2 flex items-center justify-center bg-white rounded-b-[4px]">
              <button type="button" class="btn-save shadow"
                :class="templatesStore.item.id == undefined ? 'cursor-not-allowed' : 'cursor-pointer'"
                :disabled="templatesStore.item.id == undefined" @click="saveTemplate()">保存</button>
            </div>
          </div>
        </div>
      </div>

    </div>

    <PopupResetChange ref="refPopupResetChange" @force-change="forceChange"></PopupResetChange>
  </div>
</template>
