<script setup>
import { ref, defineExpose } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue'

const isOpenDialog = ref(false)

const show = ($value=true) => {
  isOpenDialog.value = $value
}
defineExpose({ show })
const emit = defineEmits(['force-change'])
</script>
<template>
  <TransitionRoot appear :show="isOpenDialog" as="template">
    <Dialog as="div" @close="(isOpenDialog = false)" class="relative z-10">
      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all border-orange-600 border-t-4">
              <!-- <div class="mt-2 text-center">
                    <p class="text-orange-600">※リセットすると入力した内容が消去されます。</p>
                  </div> -->

              <div class="mt-3 text-center">
                <p class="text-slate-900 font-bold">保存していない変更内容は破棄されますがよろしいですか？</p>
              </div>

              <div class="mt-8 flex mx-auto justify-center gap-4">
                <button type="button" class="bg-orange-600 text-white rounded shadow px-4 py-2 hover:opacity-80"
                  @click="emit('force-change')">はい</button>
                <button type="button"
                  class="bg-white border border-default rounded shadow text-slate-500 px-4 py-2 hover:opacity-80"
                  @click="(isOpenDialog = false)">いいえ</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
