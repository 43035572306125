<template>
  <div class="h-screen min-h-full bg-slate-200 px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
    <main class="sm:flex">
      <p class="text-4xl font-bold tracking-tight text-rose-600 sm:text-5xl">404</p>
      <div class="sm:ml-6">
        <div class="sm:border-l sm:border-gray-200 sm:pl-6">
          <h1 class="text-6xl font-bold tracking-tight text-gray-600">オット！</h1>
          <p class="mt-6 text-3xl text-gray-500">ページが見つからないよ。。</p>
        </div>

        <div class="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
          <a href="/chat" class="inline-flex items-center rounded border border-transparent bg-sky-700 px-4 py-2 text-2xl font-medium text-white shadow-sm hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-800">戻る</a>
        </div>
      </div>
    </main>
  </div>
</template>