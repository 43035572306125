<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'

import emailLayout from './emailLayout.vue';
import lineLayout from './lineLayout.vue';
import zaloLayout from './zaloLayout.vue';

import PopupResetChange from 'views/common/PopupResetChange.vue';
const refPopupResetChange = ref(null)

const currentTab = ref(false)
const tabs = ref([
  { value: 'email', text: 'メール設定' },
  { value: 'line', text: 'ライン設定' },
  { value: 'zalo', text: 'Zalo設定' },
])

import { SettingsStore } from "store/settings";
const settingsStore = SettingsStore();

const changeTab = ($tab) => {
  if(settingsStore.type == $tab) {
    return
  }
  if (settingsStore.isChanged) {
    //show alert change
    refPopupResetChange.value.show(true)
    currentTab.value = $tab
    return
  }
  else{
    settingsStore.type = $tab
  }
}
const forceChange = () => {
  refPopupResetChange.value.show(false)
  settingsStore.type = currentTab.value
}
</script>
<template>
    <div class="w-full max-w-[700px] mx-auto lg:flex-row lg:py-16">
      <div class="w-full flex flex-row gap-4 justify-center border-b-[1px]">
        <template v-for="tab in tabs">
          <div class="flex cursor-pointer pt-2 hover:text-yellow-650" @click="changeTab(tab.value)"
            :class="settingsStore.type == tab.value ? 'text-yellow-650 font-bold border-b-[2px] border-blue-800' : ''">{{ tab.text }}</div>
        </template>
      </div>
      <div class="pt-4">
        <emailLayout v-if="settingsStore.type == 'email'"/>
        <lineLayout v-else-if="settingsStore.type == 'line'"/>
        <zaloLayout v-else-if="settingsStore.type == 'zalo'"/>
      </div>

      <PopupResetChange ref="refPopupResetChange" @force-change="forceChange"></PopupResetChange>

  </div>

</template>
