<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue'
const $root = getCurrentInstance()?.appContext.config.globalProperties

import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline'

import PopupResetEmail from './PopupResetEmail.vue';
const refPopupResetEmail = ref(null)

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import common from 'store/common'

import { SettingsStore } from "store/settings";
const settingsStore = SettingsStore();

const optionAutoDelete = ref([
  { value: '0', text: '受信メールをサーバーに残す' },
  { value: '1', text: 'サーバーからメールを削除' },
])

let showPass = ref(false);
let showSmtpPass = ref(false);

const arrEmailType = ref([
  { value: 'gmail', text: 'Gmail' },
  { value: 'host_email', text: 'Host Mail' },
])

onMounted(async () => {
  settingsStore.type = 'email'
  //get list template
  await settingsStore.getSetting()
})

const changEmailType = async () => {
  settingsStore.item = settingsStore.intValue(settingsStore.item.type_email)
  settingsStore.tempItem = Object.assign({}, settingsStore.item)
  showPass.value = false
  showSmtpPass.value = false
}

const clearSetting = async () => {
  $root.$overlay.show()
  const response = await settingsStore.deleteSettingEmail()
  if (response.code == 200) {
    refPopupResetEmail.value.show(false)
    
    //reload sample value
    settingsStore.getSetting()
  }
  else {
    $root.notify({ group: 'top', message: t('msg_save_error'), type: 'error' })
  }
  $root.$overlay.hide()
}

const save = async () => {
  checkForm()
  if (Object.keys(settingsStore.error).length > 0) {
    $root.notify({ group: 'top', message: t('require_field_notice'), type: 'error' })
    return
  }
  //save function here
  $root.$overlay.show()
  settingsStore.saveSetting().then(response => {
    if (response == 'error') {
      $root.notify({ group: 'top', message: t('settings_receive_email_error'), type: 'error'})
      $root.$overlay.hide()
      return
    }
    
    let _msg = ''
    let type = 'warning'
    if(response.code == 200 && response.errors.length==0){
      _msg = t('msg_success')
      type = 'success'

      //get config
      settingsStore.getSetting()
    }
    else{
      if(response.errors.getEmail){
        _msg += t('settings_receive_email_error')
      }
      if(response.errors.sendEmail){
        _msg += t('settings_send_email_error')
      }
    }
    $root.notify({ group: 'top', message: _msg, type: type})

    $root.$overlay.hide()
  })

  
}
const checkForm = () => {
  settingsStore.error = {}

  if (!settingsStore.item.name) {
    settingsStore.error.name = true
  }
  if (!settingsStore.item.email || !common.isValidEmail(settingsStore.item.email)) {
    settingsStore.error.email = true
  }
  if (!settingsStore.item.password) {
    settingsStore.error.password = true
  }

  if (settingsStore.item.type_email == 'host_email') {

    if (!settingsStore.item.host) {
      settingsStore.error.host = true
    }

    if (!settingsStore.item.port) {      
      settingsStore.error.port = true
    }
    else{
      settingsStore.item.port = parseInt(settingsStore.item.port)
      if(settingsStore.item.port < 1){
        settingsStore.error.port = true
      }
    }

    settingsStore.item.interval = parseInt(settingsStore.item.interval)
    if (!settingsStore.item.interval || settingsStore.item.interval < 1) {
      settingsStore.error.interval = true
    }

    if (!settingsStore.item.smtp_host) {
      settingsStore.error.smtp_host = true
    }
    if (!settingsStore.item.smtp_username) {
      settingsStore.error.smtp_username = true
    }
    if (!settingsStore.item.smtp_password) {
      settingsStore.error.smtp_password = true
    }

    if (!settingsStore.item.smtp_port) {
      settingsStore.error.smtp_port = true
    }
    else {
      settingsStore.item.smtp_port = parseInt(settingsStore.item.smtp_port)
      if(settingsStore.item.smtp_port < 1) {
        settingsStore.error.smtp_port = true
      }
    }
  }
}

</script>
<template>
  <div class="flex flex-col gap-4">
    <div class="flex justify-between">
      <div class="flex text-2xl font-bold text-blue-910 items-center">メール設定</div>
      <div>
        <button class="btn-delete shadow" @click="refPopupResetEmail.show()"
          v-if="!settingsStore.default">メール送受信設定を解除</button>
      </div>
    </div>

    <!-- block select email type when the user has not set up email yet  -->
    <div class="flex bg-white flex-col divide-y divide-slate-300 rounded border text-sm border-slate-400 hidden" v-show="settingsStore.default">
      <div class="p-2 flex gap-2 items-center rounded-t-[4px]">
        <div class="w-48 flex-none ">電子メールの種類:</div>
        <div class="grow">
          <select class="border border-default rounded h-7 px-1" v-model="settingsStore.item.type_email" @change="changEmailType">
            <template v-for="email in arrEmailType">
              <option :value="email.value" v-text="email.text"></option>
            </template>
          </select>
        </div>
      </div>
    </div>

    <!-- block setting gmail -->
    <div class="flex bg-white flex-col divide-y divide-slate-200 rounded border drop-shadow text-sm border-slate-400">
      <template v-if="settingsStore.item.type_email == 'gmail'">
        <div class="p-1 flex items-center bg-slate-150">
          メール情報と設定
        </div>

        <div class="p-2 flex gap-2 items-center">
          <div class="w-48 flex-none ">差出人名</div>
          <div class="grow">
            <input type="text" class="rounded h-7 border px-2 w-full " autocomplete="nope"
              v-model.trim="settingsStore.item.name" :class="settingsStore.error.name ? 'border-red-500' : 'border-default'" />
          </div>
          <div class="w-12 flex-none text-red-500 text-xs">※必須</div>
        </div>

        <div class="p-2 flex gap-2 items-center">
          <div class="w-48 flex-none">メールアドレス</div>
          <div class="grow">
            <input class="rounded h-7 border px-2 w-full" autocomplete="nope" required
              v-model.trim="settingsStore.item.email"
              :class="settingsStore.error.email ? 'border-red-500' : 'border-default'" />
          </div>
          <div class="w-12 flex-none text-red-500 text-xs">※必須</div>
        </div>

        <div class="p-2 flex gap-2 items-center">
          <div class="w-48 flex-none">App パスワード</div>
          <div class="grow">
            <div class="relative">
              <input v-model.trim="settingsStore.item.password" :type="showPass ? 'text' : 'password'" required id="password"
                placeholder="********" autocomplete="nope" :class="settingsStore.error.email ? 'border-red-500' : 'border-default'"
                class="rounded h-7 border px-2 w-full " />
              <button type="button" @click="showPass = !showPass"
                class="absolute inset-y-0 right-0 flex items-center pr-3">
                <EyeIcon v-if="showPass" class="h-5 w-5 text-slate-750" aria-hidden="true" />
                <EyeSlashIcon v-else class="h-5 w-5 text-slate-750" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div class="w-12 flex-none text-red-500 text-xs">※必須</div>
        </div>
      </template>
      <!-- block setting host email -->
      <template v-else>
        <div class="p-1 flex items-center bg-slate-150">
          メール情報と設定
        </div>

        <div class="p-2 flex gap-2 items-center">
          <div class="w-48 flex-none">アカウントの種類</div>
          <div class="grow">
            <select class="border border-default rounded h-7 px-1" v-model.trim="settingsStore.item.type">
              <option v-for="oType in ['IMAP','POP3']" :value="oType">{{oType}}</option>
            </select>
          </div>
          <div class="w-12 flex-none text-red-600"></div>
        </div>

        <div class="p-2 flex gap-2 items-center">
          <div class="w-48 flex-none">ホスト名</div>
          <div class="grow">
            <input type="text" class="rounded h-7 border px-2 w-full"
              placeholder="E.g: smtp-server.inbox.com" v-model.trim="settingsStore.item.host"
              :class="settingsStore.error.host ? 'border-red-500' : 'border-default'" />
          </div>
          <div class="w-12 flex-none text-red-500 text-xs">※必須</div>
        </div>

        <div class="p-2 flex gap-2 items-center">
          <div class="w-48 flex-none">ユーザー名</div>
          <div class="grow">
            <input type="text" class="rounded h-7 border px-2 w-full" placeholder="E.g: info@inbox.com"
              v-model.trim="settingsStore.item.email" :class="settingsStore.error.email ? 'border-red-500' : 'border-default'" />
          </div>
          <div class="w-12 flex-none text-red-500 text-xs">※必須</div>
        </div>

        <div class="p-2 flex gap-2 items-center">
          <div class="w-48 flex-none">パスワード</div>
          <div class="grow">
            <div class="relative">
              <input v-model.trim="settingsStore.item.password" :type="showPass ? 'text' : 'password'" required id="password"
                placeholder="********" autocomplete="nope" :class="settingsStore.error.password ? 'border-red-500' : 'border-default'"
                class="rounded h-7 border px-2 w-full " />
              <button type="button" @click="showPass = !showPass"
                class="absolute inset-y-0 right-0 flex items-center pr-3">
                <EyeIcon v-if="showPass" class="h-5 w-5 text-slate-750" aria-hidden="true" />
                <EyeSlashIcon v-else class="h-5 w-5 text-slate-750" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div class="w-12 flex-none text-red-500 text-xs">※必須</div>
        </div>

        <div class="p-2 flex gap-2 items-center">
          <div class="w-48 flex-none">ポート番号</div>
          <div class="grow">
            <input type="number" class="rounded h-7 border px-2 w-24" placeholder="E.g: 465"
              v-model.trim="settingsStore.item.port" :class="settingsStore.error.port ? 'border-red-500' : 'border-default'" />
          </div>
          <div class="w-12 md:w-40 lg:w-3/5 flex-none text-red-500 text-xs">※必須</div>
        </div>

        <div class="p-2 flex gap-2 items-center">
          <div class="">
            <label class="flex h-6 items-center gap-1">
              <input type="checkbox" v-model.trim="settingsStore.item.encryption"
                class="h-5 w-5 rounded border-default checked:bg-indigo-600 focus:ring-indigo-500">
              このサーバーには安全な接続が必要です (SSL/TLS)
            </label>
          </div>
        </div>

        <div class="p-2 flex gap-2 items-center">
          <div class="w-48 flex-none">自動取得の頻度</div>
          <div class="grow">
            <div class="flex items-center gap-1">
              <input type="number" class="rounded h-7 border px-2 w-24"
                v-model.number="settingsStore.item.interval" :class="settingsStore.error.interval ? 'border-red-500' : 'border-default'" />分
            </div>
          </div>
          <div class="w-12 md:w-40 lg:w-3/5 flex-none text-red-500 text-xs">※必須</div>
        </div>

        <div class="p-2 flex items-start">
          <div class="w-48 flex-none">取得したメールの削除</div>
          <div class="flex flex-col gap-2 pl-3">
            <label class="h-5 flex items-center text-sm" v-for="oDelete in optionAutoDelete"
              :for="oDelete.value + '_delete'">
              <input type="radio" :id="oDelete.value + '_delete'" :value="oDelete.value"
                class="h-5 w-5 border-default text-indigo-600 focus:ring-indigo-500"
                v-model.trim="settingsStore.item.auto_delete" />
              <span class="ml-1" v-text="oDelete.text"></span>
            </label>
          </div>
        </div>

        <div class="p-1 flex items-center bg-slate-150">
          SMTP経由でメールを送信
        </div>

        <div class="p-2 flex gap-2 items-center">
          <div class="w-48 flex-none">差出人名</div>
          <div class="grow">
            <input type="text" class="rounded h-7 border px-2 w-full"
              v-model.trim="settingsStore.item.name" :class="settingsStore.error.name ? 'border-red-500' : 'border-default'" />
          </div>
          <div class="w-12 flex-none text-red-500 text-xs">※必須</div>
        </div>

        <div class="p-2 flex gap-2 items-center">
          <div class="w-48 flex-none">ホスト名</div>
          <div class="grow">
            <input type="text" class="rounded h-7 border px-2 w-full"
              placeholder="E.g: smtp-server.inbox.com" v-model.trim="settingsStore.item.smtp_host"
              :class="settingsStore.error.smtp_host ? 'border-red-500' : 'border-default'" />
          </div>
          <div class="w-12 flex-none text-red-500 text-xs">※必須</div>
        </div>

        <div class="p-2 flex gap-2 items-center">
          <div class="w-48 flex-none">ユーザー名</div>
          <div class="grow">
            <input type="text" class="rounded h-7 border px-2 w-full" placeholder="E.g: info@inbox.com"
              v-model.trim="settingsStore.item.smtp_username"
              :class="settingsStore.error.smtp_username ? 'border-red-500' : 'border-default'" />
          </div>
          <div class="w-12 flex-none text-red-600"></div>
        </div>

        <div class="p-2 flex gap-2 items-center">
          <div class="w-48 flex-none">パスワード</div>
          <div class="grow">
            <div class="relative">
              <input v-model.trim="settingsStore.item.smtp_password" :type="showSmtpPass ? 'text' : 'password'" required id="smtp_password"
                placeholder="********" autocomplete="nope" :class="settingsStore.error.smtp_password ? 'border-red-500' : 'border-default'"
                class="rounded h-7 border px-2 w-full " />
              <button type="button" @click="showSmtpPass = !showSmtpPass"
                class="absolute inset-y-0 right-0 flex items-center pr-3">
                <EyeIcon v-if="showSmtpPass" class="h-5 w-5 text-slate-750" aria-hidden="true" />
                <EyeSlashIcon v-else class="h-5 w-5 text-slate-750" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div class="w-12 flex-none text-red-500 text-xs">※必須</div>
        </div>

        <div class="p-2 flex gap-2 items-center">
          <div class="w-48 flex-none">ポート番号</div>
          <div class="grow">
            <input type="number" class="rounded h-7 border px-2 w-24" placeholder="E.g: 465"
              v-model.trim="settingsStore.item.smtp_port" :class="settingsStore.error.smtp_port ? 'border-red-500' : 'border-default'" />
          </div>
          <div class="w-12 md:w-40 lg:w-3/5 flex-none text-red-500 text-xs">※必須</div>
        </div>

        <div class="p-2 flex gap-2 items-center">
          <div class="">
            <label class="flex h-6 items-center gap-1">
              <input type="checkbox" v-model.trim="settingsStore.item.smtp_encryption"
                class="h-5 w-5 rounded border-default checked:bg-indigo-600 focus:ring-indigo-500">
              このサーバーには安全な接続が必要です (SSL/TLS)
            </label>
          </div>
        </div>

      </template>
      <div class="p-2 flex items-center justify-center">
        <button class="btn-save shadow"
          @click="save()">保存</button>
      </div>
    </div>
    <PopupResetEmail ref="refPopupResetEmail" @clear-setting="clearSetting"/>
  </div>
</template>
