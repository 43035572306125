<script setup>
import { ref, onMounted, onUnmounted} from 'vue'


import WidgetRoom from './WidgetRoom.vue';
import WidgetMain from './WidgetMain.vue';
import WidgetInfo from './WidgetInfo.vue';

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import { ChatsStore } from "store/chats";
const chatsStore = ChatsStore();

onMounted(async () => {
  chatsStore.connectSocket()
})

onUnmounted(() =>{
  chatsStore.disconnectSocket();
})

</script>
<template>
  <div class="flex flex-row mx-auto w-full min-h-screen">

    <div class="w-full lg:w-3/12 bg-[#E7ECF0] relative">
      <WidgetRoom/>
    </div>

    <div class="w-full lg:w-6/12 bg-yellow-70 border-l border-r border-[#686F84] relative">
        <WidgetMain :room_id="chatsStore.room.id" v-if="chatsStore.room.id > 0"  :key="chatsStore.keyView"/>
    </div>

    <div class="w-full lg:w-3/12 bg-chat-info lg:min-w-[475px]">
        <WidgetInfo :room_id="chatsStore.room.id" v-if="chatsStore.room.id > 0"  :key="chatsStore.keyView"/>
    </div>
  </div>

</template>
