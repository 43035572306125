import axios from "axios";
import { defineStore } from "pinia";
import common from 'store/common'

export const UsersStore = defineStore('users-store', {
  state: () => ({
    users: [],    
    errors: [],

    user: {},
    error: {},
  }),

  actions: {
    async getList() {
      const response = await axios.get("/api/users");
      if(response.data.length > 0) {
        this.users = response.data
      }
      
    },

    async saveNewUser() {
      const response = await axios.post('/api/users', this.user)
      return response.data
    },

    validateUser(){
      this.error = {}
      if(!this.user.name){
        this.error.name = true
      }
      const emailRegex = common.isValidEmail(this.user.email)
      if(!this.user.email || !emailRegex){
        this.error.email = true
      }
    },

    async saveAll() {      
      //put data to api to save
      const response = await axios.post('/api/users/save-all', this.users)
      if(response.data.users.length > 0) {
        this.users = response.data.users
      }
      return response.data
    },

    async validateUsers(){
      this.errors = []
      let checkDupicateEmail = []
      this.users.forEach((user,idx) => {
        const emailRegex = common.isValidEmail(user.email)
        this.errors[idx] = {}
        if(!user.name){
          this.errors[idx].name = true
        }
        if(!user.email || !emailRegex){
          this.errors[idx].email = true
        }
        if(checkDupicateEmail.indexOf(user.email) >= 0){
          this.errors[idx].email = true
        }
        checkDupicateEmail[idx] = user.email
      });
      
    },
    async delete(id) {
      const response = await axios.delete(`/api/users/${id}`);

      if(response.data.users.length > 0) {
        this.users = response.data.users
      }
      
      return response.data;
    },
  }
});